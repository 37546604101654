<script setup>
import googleImage from "@images/illustrations/google.png";
import microsoftImage from "@images/illustrations/microsoft.png";
import serverImage from "@images/illustrations/server.png";

definePage({
  meta: {
    requiresAuth: true,
    title: "Email Accounts - Connect",
  },
});

const providers = [
{
    title: "Any Email Provider",
    btnText: "Connect",
    color: "info",
    description: "Connect with any SMTP provider.",
    image: serverImage,
    path: "/email-accounts/connect/smtp",
    disabled: false,
  },
  {
    title: "Google",
    btnText: "Coming Soon",
    color: "warning",
    description: "Connect with Google Workspace / GSuit.",
    image: googleImage,
    path: "/email-accounts/connect/google",
    disabled: true,
  },
  {
    title: "Microsoft",
    btnText: "Coming Soon",
    color: "error",
    description: "Connect with Outlook / Office 365.",
    image: microsoftImage,
    path: "/email-accounts/connect/microsoft",
    disabled: true,
  },

];
</script>

<template>
  <div class="mb-6">
    <VRow class="py-6">
      <VCol cols="12">
        <div class="d-flex flex-wrap justify-start justify-sm-space-between gap-y-4 gap-x-6 mb-6">
          <div class="d-flex flex-column justify-center">
            <h4 class="text-h4 font-weight-medium">
              Connect Email Accounts
            </h4>
            <div class="text-wrap text-body-1" style="max-inline-size: 360px;">
              Connect with any of these providers.
          </div>
          </div>
         </div>

      </VCol>
    </VRow>

    <VRow>
      <VCol
        v-for="{ title, btnText, color, description, image, path,disabled } in providers"
        :key="title"
        cols="12"
        md="6"
      >
        <VCard
          flat
          :disabled="disabled"
          :color="`rgba(var(--v-theme-${color}), var(--v-selected-opacity))`"
        >
          <VCardText>
            <div
              class="d-flex justify-space-between gap-4 flex-column-reverse flex-sm-row"
            >
              <div class="text-center text-sm-start">
                <h5 class="text-h5 mb-1" :class="`text-${color}`">
                  <div
                    class="d-flex justify-space-between gap-4 flex-column-reverse flex-sm-row"
                  >
                    <div class="text-center text-sm-start">
                      <h5 class="text-h5 mb-1" :class="`text-${color}`">
                        {{ title }}
                      </h5>
                    </div>
                  </div>
                </h5>
                <p class="text-body-1 mx-auto" style="max-inline-size: 300px">
                  {{ description }}
                </p>
                <VBtn :color="color" :to="path" :disabled="disabled">
                  {{ btnText }}
                </VBtn>
              </div>

              <div class="align-self-center">
                <div class="align-self-center">
                  <img :src="image" height="90" class="flip-in-rtl" />
                </div>
              </div>
            </div>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </div>
</template>
