<script setup>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { VNodeRenderer } from '@/@layouts/components/VNodeRenderer'
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import { themeConfig } from '@themeConfig'
import authV2RegisterIllustrationBorderedDark from '@images/pages/auth-v2-register-illustration-bordered-dark.png'
import authV2RegisterIllustrationBorderedLight from '@images/pages/auth-v2-register-illustration-bordered-light.png'
import authV2RegisterIllustrationDark from '@images/pages/brain.png'
import authV2RegisterIllustrationLight from '@images/pages/brain.png'
import authV2MaskDark from '@images/pages/misc-mask-dark.png'
import authV2MaskLight from '@images/pages/misc-mask-light.png'
import axios from 'axios'
import { useLoadingOverlay } from '@/composables/useLoadingOverlay'
import { useSnackbar } from "@/composables/useSnackbar"
import { useAuthStore } from "@/store/auth";
import { useApiService } from "@/common/apiService";

const authStore = useAuthStore();
const loading = useLoadingOverlay()
const { showSnackbar } = useSnackbar()
const apiService = useApiService();

definePage({
  meta: {
    requiresAuth: false,
    title: "Complete Your Registration",
    layout: "blank",
  },
})

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:9900/api'

const form = reactive({
  email: '',
  full_name: '',
  password: '',
  password_confirm: '',
  company_name: '',
})

const errors = ref({})
const isPasswordVisible = ref(false)
const isConfirmPasswordVisible = ref(false)
const registrationComplete = ref(false)
const isTokenValid = ref(false)
const token = ref('')
const planDetails = ref(null)

const imageVariant = useGenerateImageVariant(authV2RegisterIllustrationLight, authV2RegisterIllustrationDark, authV2RegisterIllustrationBorderedLight, authV2RegisterIllustrationBorderedDark, true)
const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark)

const setAuthData = (data) => {
  const { user, token } = data;

  if (user && token) {
    // Update the auth store with the new data structure
    const auth_data = {
      access: token.access_token,
      refresh: token.refresh_token,
      user: user,
    };
    console.log('auth_data: ', auth_data);
    authStore.clearAuthData()
    authStore.setAuthData(auth_data)

  }
}


const route = useRoute();
const router = useRouter();

onMounted(async () => {
  token.value = route.params.token
  console.log("Payment success token:", token.value);

  if (!token.value) {
    showSnackbar({
      message: "Invalid registration link. Please try again or contact support.",
      color: "error",
    })
    return
  }

  try {
    loading.start('Verifying registration...')
    const response = await axios.get(`${API_BASE_URL}/subscriptions/verify-onboarding-token/${token.value}/`)
    
    if (response.status === 200) {
      isTokenValid.value = true
      if (response.data.email) {
        form.email = response.data.email
      }

      if (response.data.full_name){
        form.full_name = response.data.full_name
      }
      planDetails.value = response.data.plan
    } else {
      throw new Error('Invalid or expired registration link')
    }
  } catch (error) {
    console.error('Token verification error:', error)
    showSnackbar({
      message: "Invalid or expired registration link. Please try again or contact support.",
      color: "error",
    })
  } finally {
    loading.stop()
  }
})

const validateForm = () => {
  errors.value = {}
  
  if (!form.email) errors.value.email = 'Email is required'
  else if (!/^\S+@\S+\.\S+$/.test(form.email)) errors.value.email = 'Invalid email format'
  
  if (!form.full_name) errors.value.full_name = 'Full name is required'

  if (!form.company_name) errors.value.company_name = 'Company name is required'
  
  if (!form.password) errors.value.password = 'Password is required'
  else if (form.password.length < 8) errors.value.password = 'Password must be at least 8 characters long'
  
  if (form.password !== form.password_confirm) errors.value.password_confirm = 'Passwords do not match'
  
  return Object.keys(errors.value).length === 0
}

const handleSubmit = async () => {
  if (!validateForm()) return

  try {
    loading.start('Creating your account...')

    if (!token.value || !isTokenValid.value) {
      throw new Error('Invalid registration token')
    }

    const response = await axios.post(`${API_BASE_URL}/subscriptions/onboarding/${token.value}/`, {
      email: form.email,
      full_name: form.full_name,
      password: form.password,
      company_name: form.company_name,
    })

    if (response.status === 201) {
      registrationComplete.value = true
      console.log('response.data:', response.data)

      setAuthData(response.data);

      // Set timezone after successful login
      await setTimeZone();
      
      setTimeout(() => {
        router.push({ name: 'root' })
      }, 2000)
    } else {
      throw new Error('Registration failed. Please try again.')
    }
  } catch (error) {
    console.error('Registration error:', error)
    errors.value = error.response?.data?.errors || { general: 'An unexpected error occurred. Please try again.' }
    showSnackbar({
      message: error.message || "Registration failed. Please try again.",
      color: "error",
    })
  } finally {
    loading.stop()
  }
}


const setTimeZone = async () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  try {
    await apiService.post("/users/set-timezone/", { timezone });
    console.log("Timezone set successfully");
  } catch (error) {
    console.error("Error setting timezone:", error);
    // Optionally show a non-blocking error message to the user
  }
};
</script>

<template>
  <RouterLink to="/">
    <div class="auth-logo d-flex align-center gap-x-3">
      <VNodeRenderer :nodes="themeConfig.app.logo" />
      <h1 class="auth-title">
        {{ themeConfig.app.title }}
      </h1>
    </div>
  </RouterLink>

  <VRow no-gutters class="auth-wrapper bg-surface">
    <VCol md="8" class="d-none d-md-flex">
      <div class="position-relative bg-background w-100 me-0">
        <div class="d-flex align-center justify-center w-100 h-100" style="padding-inline: 100px;">
          <VImg max-width="500" :src="imageVariant" class="auth-illustration mt-16 mb-2" />
        </div>
        <img class="auth-footer-mask flip-in-rtl" :src="authThemeMask" alt="auth-footer-mask" height="280" width="100">
      </div>
    </VCol>

    <VCol cols="12" md="4" class="auth-card-v2 d-flex align-center justify-center">
      <VCard v-if="registrationComplete" flat :max-width="500" class="mt-12 mt-sm-0 pa-6">
        <VCardText>
          <h4 class="text-h4 mb-1">
            Registration Complete! 🎉
          </h4>
          <p class="text-body-1 mb-3">
            Your account has been successfully created. You will be redirected to the dashboard shortly.
          </p>
        </VCardText>
      </VCard>

      <VCard v-else-if="isTokenValid" flat :max-width="500" class="mt-12 pa-6">
        <VCardText>
          <h4 class="text-h4 mb-1">Congratulations! 🎊</h4>
          <p class="mb-2">Your payment was successful and you're almost ready to go!</p>
          <p v-if="planDetails" class="mb-4">
            You've subscribed to the <strong>{{ planDetails.name }}</strong> plan.
          </p>
          <h5 class="text-h5 mb-1">Complete Your Registration 🚀</h5>
          <p class="mb-0">Just a few more details to get you started.</p>
        </VCardText>

        <VCardText>
          <VForm @submit.prevent="handleSubmit">
            <VRow>
              <VCol cols="12">
                <AppTextField
                  v-model="form.full_name"
                  label="Full Name"
                  placeholder="John Doe"
                  :error-messages="errors.full_name"
                />
              </VCol> 
              
              <VCol cols="12">
                <AppTextField
                  v-model="form.company_name"
                  label="Company Name"
                  placeholder="Company Inc."
                  :error-messages="errors.company_name"
                />
              </VCol>

              <VCol cols="12">
                <AppTextField
                  v-model="form.email"
                  label="Email"
                  type="email"
                  placeholder="johndoe@email.com"
                  :error-messages="errors.email"
                />
              </VCol>

              <VCol cols="12">
                <AppTextField
                  v-model="form.password"
                  label="Password"
                  placeholder="············"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                  :error-messages="errors.password"
                />
              </VCol>

              <VCol cols="12">
                <AppTextField
                  v-model="form.password_confirm"
                  label="Confirm Password"
                  placeholder="············"
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isConfirmPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                  :error-messages="errors.password_confirm"
                />
              </VCol>

              <VCol cols="12">
                <VBtn block type="submit">
                  Complete Registration
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>

      <VCard v-else flat :max-width="500" class="mt-12 pa-6">
        <VCardText>
          <h4 class="text-h4 mb-1">Invalid Registration Link</h4>
          <p class="mb-0">This registration link is invalid or has expired. Please contact support or try again.</p>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
