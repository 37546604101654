<script setup>
import { useApiService } from "@/common/apiService";
import AudienceProfileStep from "@/components/campaigns/create/AudienceProfileStep.vue";
import BusinessInformationStep from "@/components/campaigns/create/BusinessInformationStep.vue";
import ConfigurationStep from "@/components/campaigns/create/ConfigurationStep.vue";
import { useLoadingOverlay } from "@/composables/useLoadingOverlay";
import { useSnackbar } from "@/composables/useSnackbar";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

// Import SVG icons and images
import paperImg from "@images/svg/paper-send.svg?raw";
import rocketImg2 from "@images/svg/rocket.svg?raw";
import userInfoImg from "@images/svg/user-info.svg?raw";
import customWizardAccount from "@images/svg/wizard-account.svg";
import customWizardPersonal from "@images/svg/wizard-personal.svg";
import customWizardSubmit from "@images/svg/wizard-submit.svg";

definePage({
  meta: {
    requiresAuth: true,
    title: "Create Campaign",
  },
});

// Initialize services and composables
const api = useApiService();
const loading = useLoadingOverlay();
const { showSnackbar } = useSnackbar();
const router = useRouter();

// Component state
const currentStep = ref(0);
const cloudTags = ref([]);
const personTitles = ref([]);
const emailAccountsData = ref([]); // unfiltered initial values from the api
const personLocations = ref([]);
const isCurrentStepValid = ref(true);

const potentialLeadsCount = ref(0);
const actionRequest = ref(null);
const errorMessage = ref("");
const startForm = ref(false);
const emailAccounts = ref([]);

// Form data for each step
const formData = reactive({
  step1: {},
  step2: {},
  step3: {},
});

// Create icon components
const createIconComponent = (svg, color = "primary") =>
  h("div", {
    innerHTML: svg,
    style: `font-size: 2.625rem;color: rgb(var(--v-theme-${color}))`,
  });

const rocketIcon = createIconComponent(rocketImg2);
const userInfoIcon = createIconComponent(paperImg);
const paperIcon = createIconComponent(userInfoImg);

/**
 * Data for each step in the campaign creation process
 */
const stepsData = [
  {
    icon: rocketIcon,
    title: "Share Your Vision",
    desc: "Tell us about your business and idea",
  },
  {
    icon: paperIcon,
    title: "AI-Powered Targeting",
    desc: "Our AI identifies your ideal prospects",
  },
  {
    icon: userInfoIcon,
    title: "Craft Your Campaign",
    desc: "Customize your outreach strategy",
  },
];
/**
 * Configuration for the stepper component
 */
const iconsSteps = [
  { title: "Business Information", icon: customWizardAccount },
  { title: "Audience Profile", icon: customWizardPersonal },
  { title: "Configuration", icon: customWizardSubmit },
];
// todo change the icons in iconsSteps above

/**
 * Computed property for the next button text
 * @returns {string} The appropriate text for the next button based on the current step
 */
const nextButtonText = computed(() => {
  if (currentStep.value === 0) return "Fetch Leads";
  if (currentStep.value === iconsSteps.length - 1) return "Create Campaign";
  return "Next";
});

/**
 * Computed property for the next button icon
 * @returns {string} The appropriate icon for the next button based on the current step
 */
const nextButtonIcon = computed(() => {
  if (currentStep.value === 0) return "tabler-sparkles";
  if (currentStep.value === iconsSteps.length - 1) return "tabler-send";
  return "tabler-arrow-right";
});

/**
 * Handles the next button click
 * Sets the action request to 'next'
 */
const handleNext = () => {
  if (nextButtonText.value === "Create Campaign") {
    actionRequest.value = { type: "submit" };
  } else {
    actionRequest.value = { type: "next" };
  }
};

/**
 * Handles the back button click
 * Sets the action request to 'back'
 */
const handleBack = () => {
  actionRequest.value = { type: "back" };
};

/**
 * Handles the save draft button click
 * Sets the action request to 'saveDraft'
 */
const handleSaveDraft = () => {
  actionRequest.value = { type: "saveDraft" };
};

/**
 * Processes the response from a step component
 * @param {Object} response - The response object from the step component
 */
const handleStepResponse = async (response) => {
  if (response.status === "success") {
    switch (response.action) {
      case "next":
        if (currentStep.value === 0) {
          formData.step1 = response.data;
          const fetchSuccess = await fetchAudienceProfile(response.data);
          if (fetchSuccess) {
            currentStep.value++;
            isCurrentStepValid.value = true;
          }
        } else {
          currentStep.value++;
          isCurrentStepValid.value = true;
        }
        break;
      case "back":
        currentStep.value--;
        break;
      case "submit":
        await createCampaign(response.data, false);
        break;
      case "saveDraft":
        await createCampaign(response.data, true);
        break;
    }
  } else {
    console.error("Step action failed:", response.error);
    errorMessage.value = "Please check your inputs and try again.";
    // scroll to top
    window.scrollTo(0, 0);
  }
  actionRequest.value = null;
};

/**
 * Fetches the audience profile based on the provided data
 * @param {Object} originalData - The data used to fetch the audience profile
 * @returns {boolean} Indicates whether the fetch was successful
 */
const fetchAudienceProfile = async (originalData) => {
  try {
    console.log("Fetching audience profile with data:", originalData);
    loading.start("Finding potential leads...");

    // Create a new data object with the desired keys
    const data = {
      companyName: originalData.companyName,
      business_description: originalData.companyDescription,
      location: originalData.targetLocation
        ? originalData.targetLocation.join(", ")
        : "",
      hasWebsite: originalData.hasWebsite,
      website_url: originalData.websiteUrl,
    };

    // Check if any value is null or empty
    const hasNullOrEmptyValue = Object.values(data).some(
      (value) => value === null || value === ""
    );

    if (hasNullOrEmptyValue) {
      console.log("fetchAudienceProfile data is empty? ", hasNullOrEmptyValue); // logs true if any value is null or empty, false otherwise
      console.log("fetchAudienceProfile data is empty: ", originalData);

      // this is because of a bug. After clicking the back button on step-2, when on step-1 then clicking next, the data is empty despite having values.
      console.error("Invalid data provided for audience profile fetch");
      errorMessage.value = "";
      return;
    }

    console.log("Sending data to API:", data);
    errorMessage.value = "";

    const response = await api.post("/campaigns/fetch-search-queries/", data);
    console.log("Audience profile fetched successfully", response.data);
    cloudTags.value = response.data.cloud_tags;
    potentialLeadsCount.value = response.data.potential_leads_count;

    console.log(
      "response potential_leads_count: ",
      response.data.potential_leads_count
    );
    console.log("potentialLeadsCount: ", potentialLeadsCount.value);

    personTitles.value = response.data.person_titles;
    personLocations.value = response.data.person_locations;

    return true;
  } catch (error) {
    console.error("Error fetching audience profile:", error);

    if (error.response.status === 406) {
      showSnackbar({
        message:
          "Unable to fetch audience profile. Please ensure the website URL is valid or your company description is detailed enough.",
        color: "error",
        timeout: 5000,
      });
      errorMessage.value =
        "Unable to fetch audience profile. Please ensure the website URL is valid or your company description is detailed enough.";
      return false;
    }

    showSnackbar({
      message: "Error fetching audience profile. Please try again.",
      color: "error",
      timeout: 5000,
    });
    errorMessage.value = "Failed to fetch audience profile. Please try again.";
    return false;
  } finally {
    loading.stop();
  }
};

/**
 * Creates a new campaign with the provided data
 * @param {Object} configurationData - The campaign data to be submitted
 */
const createCampaign = async (configurationData, isdraft) => {
  try {
    console.log("Creating campaign with data:", configurationData);
    console.log("Form data:", formData.step1);
    console.log("isdraft:", isdraft);

    // todo validate leads_limit

    const emails = configurationData.sendingEmails;
    const emailUUIDs = emailAccountsData.value
      .filter((item) => emails.includes(item.email))
      .map((item) => item.uuid);

    loading.start("Creating campaign...");
    const campaignData = {
      name: configurationData.campaignName,
      sender_name: configurationData.senderName,
      description:
        configurationData.campaignDescription ||
        "Campaign created with LeaduxAI",
      email_accounts: emailUUIDs,
      status: isdraft ? "draft" : "active",
      cloud_tags: cloudTags.value,
      send_limit_per_day: configurationData.dailyLimit,
      leads_limit: configurationData.numberOfLeads,
      time_between_sends: 5, // Default value,
      start_time: "09:00:00", // Default value
      end_time: "17:00:00", // Default value
      running_days: [0, 1, 2, 3, 4], // Default to weekdays
      include_unsubscribe_link: configurationData.addUnsubscribeLink,
      track_email_opens: configurationData.trackEmailOpens,
      send_as_text_only: configurationData.sendPlainTextEmails,
      sequence_choice: configurationData.emailSequence,
      ai_reply_enabled: configurationData.aiReplies,
      target_website_url: formData.step1.websiteUrl,
      target_website_details: formData.step1.companyDescription,
      target_locations: personLocations.value,
      objective: configurationData.campaignObjective,
      personalization_data: configurationData.campaignAdditionalInfo || {},
      person_titles: personTitles.value,
      company_name: formData.step1.companyName,
    };

    // console.table(campaignData);

    const response = await api.post(
      "/campaigns/campaign-create/",
      campaignData
    );
    console.log("Campaign created successfully", response.data);

    showSnackbar({
      message: isdraft
        ? "Campaign draft created successfully!"
        : "Campaign created successfully!",
      color: "success",
      timeout: 5000,
    });

    // redirect to the campaign detail page
    router.push({ name: "campaigns-id", params: { id: response.data.uuid } });
  } catch (error) {
    console.error("Error creating campaign:", error);

    let errorMessage = "Error creating campaign. Please try again.";

    // Check if the error code is 426
    if (error.response && error.response.status === 406) {
      errorMessage =
        "You have reached the maximum number of campaigns allowed. Please upgrade your plan.";
    } else if (error.response && error.response.data) {
      const firstErrorKey = Object.keys(error.response.data)[0];
      if (firstErrorKey) {
        const fieldName = getFieldDisplayName(firstErrorKey);
        const errorDetail = error.response.data[firstErrorKey][0];
        errorMessage = `${fieldName}: ${errorDetail}`;
      }
    }

    showSnackbar({
      message: errorMessage,
      color: "error",
      timeout: 5000,
    });
  } finally {
    loading.stop();
  }
};

// Helper function to get a display-friendly field name
const getFieldDisplayName = (fieldName) => {
  const fieldMap = {
    name: "Campaign Name",
    sender_name: "Sender Name",
    email_accounts: "Email Accounts",
    send_limit_per_day: "Daily Send Limit",
    include_unsubscribe_link: "Unsubscribe Link",
    track_email_opens: "Track Email Opens",
    ai_reply_enabled: "AI Replies",
    send_as_text_only: "Send as Text Only",
    sequence_choice: "Email Sequence",
    manual_email_count: "Number of Follow-ups",
    leads_limit: "Number of Leads",
  };

  return (
    fieldMap[fieldName] ||
    fieldName.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())
  );
};

const fetchEmailAccounts = async () => {
  try {
    const response = await api.get("/campaigns/email-accounts/?all=true");
    emailAccounts.value = response.data;
    emailAccountsData.value = response.data;

    const emails = emailAccounts.value.map((item) => item.email);
    emailAccounts.value = emails;
  } catch (error) {
    console.error("Error fetching email accounts:", error);
    // Handle error (e.g., show error message to user)
    showSnackbar({
      message: "Error fetching email accounts. Please try again.",
      color: "error",
      timeout: 3000,
    });
  } finally {
  }
};

onMounted(() => {
  fetchEmailAccounts();
});
</script>

<template>
  <VRow class="center-content" align="center" justify="center">
    <VCol v-if="!startForm" cols="12" md="10" lg="8" class="mx-auto">
      <VCard class="get-started-card" elevation="8">
        <VCardItem>
          <VCardTitle class="text-h4 font-weight-bold text-center mb-4">
            Get started
          </VCardTitle>
          <VCardSubtitle class="text-body-1 text-center">
            Let the wizard guide you to create a campaign in 3 simple steps.
          </VCardSubtitle>
        </VCardItem>
        <VCardText>
          <VRow class="mt-8">
            <VCol
              v-for="(step, index) in stepsData"
              :key="index"
              cols="12"
              md="4"
            >
              <VCard
                v-bind="props"
                :elevation="isHovering ? 8 : 2"
                :class="{ 'on-hover': isHovering }"
                class="step-card pa-4 d-flex flex-column align-center"
              >
                <VIcon
                  :icon="step.icon"
                  color="primary"
                  size="64"
                  class="mb-4 to-float"
                />
                <div class="text-h6 font-weight-medium text-center mb-2">
                  {{ step.title }}
                </div>
                <div class="text-body-2 text-center">
                  {{ step.desc }}
                </div>
              </VCard>
            </VCol>
          </VRow>

          <div class="mt-12 text-center">
            <VBtn @click="startForm = true" variant="tonal" block>
              Lets go
              <VIcon end icon="tabler-sparkles" />
            </VBtn>
          </div>
        </VCardText>
      </VCard>
    </VCol>

    <VCol v-if="startForm" cols="10" class="my-auto">
      <VCard>
        <VCardText>
          <!-- 👉 Stepper -->
          <AppStepper
            v-model:current-step="currentStep"
            :items="iconsSteps"
            align="center"
            :is-active-step-valid="isCurrentStepValid"
          />
        </VCardText>

        <VDivider />

        <!-- Copy idea from Apollo. https://app.apollo.io/#/settings/content-center/overview -->
        <VCardText>
          <VAlert
            v-if="errorMessage"
            type="error"
            class="mb-4"
            closable
            close-label="Close Alert"
            elevation="5"
            variant="tonal"
          >
            {{ errorMessage }}
          </VAlert>

          <VWindow v-model="currentStep" class="disable-tab-transition">
            <VWindowItem>
              <BusinessInformationStep
                ref="step1"
                :action-request="actionRequest"
                @step-response="handleStepResponse"
              />
            </VWindowItem>

            <VWindowItem>
              <AudienceProfileStep
                ref="step2"
                :cloud-tags="cloudTags"
                :potential-leads-count="potentialLeadsCount"
                :action-request="actionRequest"
                @step-response="handleStepResponse"
              />
            </VWindowItem>

            <VWindowItem>
              <ConfigurationStep
                ref="step3"
                :email-accounts="emailAccounts"
                :initial-number-of-leads="potentialLeadsCount"
                :action-request="actionRequest"
                @step-response="handleStepResponse"
              />
            </VWindowItem>
          </VWindow>

          <div class="d-flex justify-space-between align-center mt-8">
            <VBtn
              color="secondary"
              variant="tonal"
              @click="handleBack"
              :disabled="currentStep === 0"
            >
              <VIcon icon="tabler-arrow-left" start />
              Back
            </VBtn>

            <VBtn color="primary" @click="handleNext">
              {{ nextButtonText }}
              <VIcon :icon="nextButtonIcon" end />
            </VBtn>
          </div>

          <VBtn
            v-if="currentStep === iconsSteps.length - 1"
            color="warning"
            variant="tonal"
            class="mt-4"
            v-show="false"
            block
            @click="handleSaveDraft"
          >
            Save as Draft
            <VIcon icon="tabler-notes-off" end />
          </VBtn>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
.card-list {
  --v-card-list-gap: 0.5rem;
}

.current-plan {
  background: linear-gradient(
    45deg,
    rgb(var(--v-theme-primary)) 0%,
    #9e95f5 100%
  );
  color: #fff;
}

.v-label--clickable {
  font-size: 0.9375rem !important;
}

.v-alert-title {
  font-size: 0.9375rem !important;
}

.get-started-card {
  border-radius: 17px;
}

.step-card {
  transition: all 0.3s ease;
  height: 100%;
}

.step-card.on-hover {
  transform: translateY(-5px);
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.v-icon.to-float {
  animation: float 3s ease-in-out infinite;
}
</style>

<style lang="scss">
.app-stepper-step {
  cursor: default !important;
}
.center-content {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
