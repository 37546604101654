import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => {
    const storedAuth = JSON.parse(localStorage.getItem("authData") || "{}");
    return {
      accessToken: storedAuth.accessToken || null,
      refreshToken: storedAuth.refreshToken || null,
      user: storedAuth.user || null,
    };
  },
  getters: {
    isAuthenticated: (state) => !!state.accessToken,
    userData: (state) => state.user,
    registration_method: (state) => state.user.registration_method,
  },
  actions: {
    setAuthData(authData) {
      this.accessToken = authData.access;
      this.refreshToken = authData.refresh;
      this.user = authData.user;
      this.persistAuthData();
    },
    clearAuthData() {
      this.accessToken = null;
      this.refreshToken = null;
      this.user = null;
      localStorage.removeItem("authData");
    },
    persistAuthData() {
      localStorage.setItem(
        "authData",
        JSON.stringify({
          accessToken: this.accessToken,
          refreshToken: this.refreshToken,
          user: this.user,
        })
      );
    },
    updateUser(userData) {
      this.user = { ...this.user, ...userData };
      this.persistAuthData();
    },

    async restoreSession(storedAuth) {
      console.log("restoreSession in progress...");
      const apiService = useApiService();

      // Set the stored tokens
      this.accessToken = storedAuth.accessToken;
      this.refreshToken = storedAuth.refreshToken;

      try {
        // Verify the token by making a request to a protected endpoint
        const response = await apiService.get("/campaigns/email-accounts/");

        // If successful, update the user data
        this.user = response.data;
        this.persistAuthData();
      } catch (error) {
        console.error("restoreSession Token verification failed:", error);

        // If verification fails, clear the auth data
        this.clearAuthData();
        throw error; // Rethrow to be caught in the router guard
      }
    },
  },
});
