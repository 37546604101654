import _definePage_default_0 from '/vercel/path0/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/vercel/path0/src/pages/[...error].vue?definePage&vue'
import _definePage_default_3 from '/vercel/path0/src/pages/account-settings/[tab].vue?definePage&vue'
import _definePage_default_4 from '/vercel/path0/src/pages/authentication/reset-password/[uid]/[token].vue?definePage&vue'
import _definePage_default_5 from '/vercel/path0/src/pages/campaigns/index.vue?definePage&vue'
import _definePage_default_6 from '/vercel/path0/src/pages/campaigns/[id].vue?definePage&vue'
import _definePage_default_7 from '/vercel/path0/src/pages/campaigns/create.vue?definePage&vue'
import _definePage_default_8 from '/vercel/path0/src/pages/email-accounts/index.vue?definePage&vue'
import _definePage_default_9 from '/vercel/path0/src/pages/email-accounts/connect/index.vue?definePage&vue'
import _definePage_default_10 from '/vercel/path0/src/pages/email-accounts/connect/google.vue?definePage&vue'
import _definePage_default_11 from '/vercel/path0/src/pages/email-accounts/connect/microsoft.vue?definePage&vue'
import _definePage_default_12 from '/vercel/path0/src/pages/email-accounts/connect/smtp.vue?definePage&vue'
import _definePage_default_13 from '/vercel/path0/src/pages/forgot-password.vue?definePage&vue'
import _definePage_default_14 from '/vercel/path0/src/pages/login.vue?definePage&vue'
import _definePage_default_15 from '/vercel/path0/src/pages/payment/failed/[token].vue?definePage&vue'
import _definePage_default_16 from '/vercel/path0/src/pages/payment/onboard/failed/[token].vue?definePage&vue'
import _definePage_default_17 from '/vercel/path0/src/pages/payment/onboard/success/[token].vue?definePage&vue'
import _definePage_default_18 from '/vercel/path0/src/pages/payment/success/[token].vue?definePage&vue'
import _definePage_default_19 from '/vercel/path0/src/pages/phone-call.vue?definePage&vue'
import _definePage_default_20 from '/vercel/path0/src/pages/pricing.vue?definePage&vue'
import _definePage_default_21 from '/vercel/path0/src/pages/register.vue?definePage&vue'
import _definePage_default_22 from '/vercel/path0/src/pages/registration/verification/[token].vue?definePage&vue'
import _definePage_default_23 from '/vercel/path0/src/pages/unibox/index.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: 'root',
    component: () => import('/vercel/path0/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/:error(.*)',
    name: '$error',
    component: () => import('/vercel/path0/src/pages/[...error].vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/account-settings',
    /* internal name: 'account-settings' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':tab',
        name: 'account-settings-tab',
        component: () => import('/vercel/path0/src/pages/account-settings/[tab].vue'),
        /* no children */
      },
  _definePage_default_3
  )
    ],
  },
  {
    path: '/authentication',
    /* internal name: 'authentication' */
    /* no component */
    children: [
      {
        path: 'reset-password',
        /* internal name: 'authentication-reset-password' */
        /* no component */
        children: [
          {
            path: ':uid',
            /* internal name: 'authentication-reset-password-uid' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':token',
                name: 'authentication-reset-password-uid-token',
                component: () => import('/vercel/path0/src/pages/authentication/reset-password/[uid]/[token].vue'),
                /* no children */
              },
  _definePage_default_4
  )
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/campaigns',
    /* internal name: 'campaigns' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'campaigns',
        component: () => import('/vercel/path0/src/pages/campaigns/index.vue'),
        /* no children */
      },
  _definePage_default_5
  ),
  _mergeRouteRecord(
      {
        path: ':id',
        name: 'campaigns-id',
        component: () => import('/vercel/path0/src/pages/campaigns/[id].vue'),
        /* no children */
      },
  _definePage_default_6
  ),
  _mergeRouteRecord(
      {
        path: 'create',
        name: 'campaigns-create',
        component: () => import('/vercel/path0/src/pages/campaigns/create.vue'),
        /* no children */
      },
  _definePage_default_7
  )
    ],
  },
  {
    path: '/email-accounts',
    /* internal name: 'email-accounts' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'email-accounts',
        component: () => import('/vercel/path0/src/pages/email-accounts/index.vue'),
        /* no children */
      },
  _definePage_default_8
  ),
      {
        path: 'connect',
        /* internal name: 'email-accounts-connect' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'email-accounts-connect',
            component: () => import('/vercel/path0/src/pages/email-accounts/connect/index.vue'),
            /* no children */
          },
  _definePage_default_9
  ),
  _mergeRouteRecord(
          {
            path: 'google',
            name: 'email-accounts-connect-google',
            component: () => import('/vercel/path0/src/pages/email-accounts/connect/google.vue'),
            /* no children */
          },
  _definePage_default_10
  ),
  _mergeRouteRecord(
          {
            path: 'microsoft',
            name: 'email-accounts-connect-microsoft',
            component: () => import('/vercel/path0/src/pages/email-accounts/connect/microsoft.vue'),
            /* no children */
          },
  _definePage_default_11
  ),
  _mergeRouteRecord(
          {
            path: 'smtp',
            name: 'email-accounts-connect-smtp',
            component: () => import('/vercel/path0/src/pages/email-accounts/connect/smtp.vue'),
            /* no children */
          },
  _definePage_default_12
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('/vercel/path0/src/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_13
  ),
  _mergeRouteRecord(
  {
    path: '/login',
    name: 'login',
    component: () => import('/vercel/path0/src/pages/login.vue'),
    /* no children */
  },
  _definePage_default_14
  ),
  {
    path: '/payment',
    /* internal name: 'payment' */
    /* no component */
    children: [
      {
        path: 'failed',
        /* internal name: 'payment-failed' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':token',
            name: 'payment-failed-token',
            component: () => import('/vercel/path0/src/pages/payment/failed/[token].vue'),
            /* no children */
          },
  _definePage_default_15
  )
        ],
      },
      {
        path: 'onboard',
        /* internal name: 'payment-onboard' */
        /* no component */
        children: [
          {
            path: 'failed',
            /* internal name: 'payment-onboard-failed' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':token',
                name: 'payment-onboard-failed-token',
                component: () => import('/vercel/path0/src/pages/payment/onboard/failed/[token].vue'),
                /* no children */
              },
  _definePage_default_16
  )
            ],
          },
          {
            path: 'success',
            /* internal name: 'payment-onboard-success' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: ':token',
                name: 'payment-onboard-success-token',
                component: () => import('/vercel/path0/src/pages/payment/onboard/success/[token].vue'),
                /* no children */
              },
  _definePage_default_17
  )
            ],
          }
        ],
      },
      {
        path: 'success',
        /* internal name: 'payment-success' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':token',
            name: 'payment-success-token',
            component: () => import('/vercel/path0/src/pages/payment/success/[token].vue'),
            /* no children */
          },
  _definePage_default_18
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/phone-call',
    name: 'phone-call',
    component: () => import('/vercel/path0/src/pages/phone-call.vue'),
    /* no children */
  },
  _definePage_default_19
  ),
  _mergeRouteRecord(
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('/vercel/path0/src/pages/pricing.vue'),
    /* no children */
  },
  _definePage_default_20
  ),
  _mergeRouteRecord(
  {
    path: '/register',
    name: 'register',
    component: () => import('/vercel/path0/src/pages/register.vue'),
    /* no children */
  },
  _definePage_default_21
  ),
  {
    path: '/registration',
    /* internal name: 'registration' */
    /* no component */
    children: [
      {
        path: 'verification',
        /* internal name: 'registration-verification' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':token',
            name: 'registration-verification-token',
            component: () => import('/vercel/path0/src/pages/registration/verification/[token].vue'),
            /* no children */
          },
  _definePage_default_22
  )
        ],
      }
    ],
  },
  {
    path: '/unibox',
    /* internal name: 'unibox' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'unibox',
        component: () => import('/vercel/path0/src/pages/unibox/index.vue'),
        /* no children */
      },
  _definePage_default_23
  )
    ],
  }
]
