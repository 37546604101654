<script setup>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import authV2ResetPasswordIllustrationDark from '@images/pages/brain.png'
import authV2ResetPasswordIllustrationLight from '@images/pages/brain.png'
import authV2MaskDark from '@images/pages/misc-mask-dark.png'
import authV2MaskLight from '@images/pages/misc-mask-light.png'
import { useApiService } from "@/common/apiService"
import { useLoadingOverlay } from '@/composables/useLoadingOverlay'
import { useSnackbar } from '@/composables/useSnackbar'

definePage({
  meta: {
    requiresAuth: false,
    title: "Reset Password",
    layout: "blank",
  },
});

const route = useRoute()
const router = useRouter()
const apiService = useApiService()
const loading = useLoadingOverlay()
const { showSnackbar } = useSnackbar()

const form = ref({
  newPassword: '',
  confirmPassword: '',
})

const uid = ref('')
const token = ref('')
const isSubmitting = ref(false)
const isPasswordVisible = ref(false)
const isConfirmPasswordVisible = ref(false)

const authThemeImg = useGenerateImageVariant(authV2ResetPasswordIllustrationLight, authV2ResetPasswordIllustrationDark)
const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark)

onMounted(() => {
  uid.value = route.params.uid
  token.value = route.params.token

  if (!uid.value || !token.value) {
    showSnackbar({
      message: 'Invalid password reset link',
      color: 'error',
    })
    router.push('/login')
  }
})

const validateForm = () => {
  if (!form.value.newPassword) {
    showSnackbar({
      message: 'Please enter a new password',
      color: 'error',
    })
    return false
  }
  if (form.value.newPassword !== form.value.confirmPassword) {
    showSnackbar({
      message: 'Passwords do not match',
      color: 'error',
    })
    return false
  }
  return true
}

const handleSubmit = async () => {
  if (!validateForm()) return

  isSubmitting.value = true
  loading.start('Resetting password...')

  try {
    const response = await apiService.post('/users/password-reset-confirm/', {
      uid: uid.value,
      token: token.value,
      new_password: form.value.newPassword,
    })

    showSnackbar({
      message: 'Password reset successful',
      color: 'success',
    })
    router.push('/login')
  } catch (error) {
    showSnackbar({
      message: error.response?.data?.detail || 'An error occurred. Please try again.',
      color: 'error',
    })
  } finally {
    isSubmitting.value = false
    loading.stop()
  }
}
</script>


<template>
  <RouterLink to="/">
    <div class="auth-logo d-flex align-center gap-x-3">
      <VNodeRenderer :nodes="themeConfig.app.logo" />
      <h1 class="auth-title">
        {{ themeConfig.app.title }}
      </h1>
    </div>
  </RouterLink>

  <VRow
    no-gutters
    class="auth-wrapper bg-surface"
  >
  <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background w-100 me-0">
        <div
          class="d-flex align-center justify-center w-100 h-100"
          style="padding-inline: 150px;"
        >
          <VImg
            max-width="451"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <img
          class="auth-footer-mask flip-in-rtl"
          :src="authThemeMask"
          alt="auth-footer-mask"
          height="280"
          width="100"
        >
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-6"
      >
        <VCardText>
          <h4 class="text-h4 mb-1">
            Reset Password 🔒
          </h4>
          <p class="mb-0">
            Your new password must be different from previously used passwords
          </p>
        </VCardText>

        <VCardText>
          <VForm @submit.prevent="handleSubmit">
            <VRow>
              <!-- password -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.newPassword"
                  autofocus
                  label="New Password"
                  placeholder="············"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
              </VCol>

              <!-- Confirm Password -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.confirmPassword"
                  label="Confirm Password"
                  placeholder="············"
                  :type="isConfirmPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="isConfirmPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'"
                  @click:append-inner="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                />
              </VCol>

              <!-- Set password -->
              <VCol cols="12">
                <VBtn
                  block
                  type="submit"
                  :loading="isSubmitting"
                  :disabled="isSubmitting"
                >
                  Set New Password
                </VBtn>
              </VCol>

              <!-- back to login -->
              <VCol cols="12">
                <RouterLink
                  class="d-flex align-center justify-center"
                  to="/login"
                >
                  <VIcon
                    icon="tabler-chevron-left"
                    size="20"
                    class="me-1 flip-in-rtl"
                  />
                  <span>Back to login</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>


<style>
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.auth-illustration {
  animation: floatAnimation 3s ease-in-out infinite;
}
</style>
