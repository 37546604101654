<script setup>
import { useTheme } from 'vuetify'
import ScrollToTop from '@core/components/ScrollToTop.vue'
import initCore from '@core/initCore'
import {
  initConfigStore,
  useConfigStore,
} from '@core/stores/config'
import { hexToRgb } from '@layouts/utils'

import { provideSnackbar } from '@/composables/useSnackbar'
import GlobalSnackbar from '@/components/GlobalSnackbar.vue'
import { provideLoadingOverlay } from '@/composables/useLoadingOverlay'
import GlobalLoadingOverlay from '@/components/GlobalLoadingOverlay.vue'

const { global } = useTheme()

// ℹ️ Sync current theme with initial loader theme
initCore()
initConfigStore()

const configStore = useConfigStore()
provideLoadingOverlay()
provideSnackbar()

</script>

<template>
  <VLocaleProvider :rtl="configStore.isAppRTL">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      
      <GlobalSnackbar />
      <GlobalLoadingOverlay />
      <ScrollToTop />
    </VApp>
  </VLocaleProvider>
</template>
