<script setup>
// Page meta
definePage({
  meta: {
    layout: "blank",
    public: false,
    requiresAuth: false,
    title: "Email Verification",
  },
});
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useApiService } from "@/common/apiService";
import { useLoadingOverlay } from "@/composables/useLoadingOverlay";
import { useSnackbar } from "@/composables/useSnackbar";
import successImage from "@images/pages/woman-celebrating.png";
import errorImage from "@images/pages/sad.png";
import axios from 'axios';
import { useAuthStore } from "@/store/auth";

const authStore = useAuthStore();


const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:9900/api';

const loading = useLoadingOverlay();
const apiService = useApiService();

const { showSnackbar } = useSnackbar();

const route = useRoute();
const router = useRouter();

const verificationStatus = ref('pending');
const statusMessage = ref('');


const setAuthData = (data) => {
  const { user, token } = data;

  if (user && token) {
    // Update the auth store with the new data structure
    const auth_data = {
      access: token.access_token,
      refresh: token.refresh_token,
      user: user,
    };
    console.log('auth_data: ', auth_data);
    authStore.clearAuthData()
    authStore.setAuthData(auth_data)

  }
}


onMounted(async () => {
  // Extract token from URL
  const token = route.params.token;
  console.log("Email verification token:", token);

  if (!token) {
    console.error("Verification token not found");
    showSnackbar({
      message: "Verification token not found",
      color: "error",
    });
    verificationStatus.value = 'error';
    statusMessage.value = "Verification token not found. Please check your email and try again.";
    return;
  }

  try {
    loading.start("Verifying your email...");
    // Call API to verify email
     
    const response = await axios.get(`${API_BASE_URL}/users/verify-email/${token}/`);
    console.log("Email verification response:", response.data);
    
    if (response.data.token) {
      verificationStatus.value = 'success';
      statusMessage.value = "Your email has been successfully verified!";
      showSnackbar({
        message: "Email verified successfully",
        color: "success",
      });

      setAuthData(response.data);

      // Set timezone after successful login
      await setTimeZone();

    // Redirect to dashboard or home page
    router.push({ name: 'root' })

    } else {
      throw new Error(response.data.error || "Verification failed");
    }
  } catch (error) {
    console.error("Email verification error:", error);
    verificationStatus.value = 'error';
    statusMessage.value = "Email verification failed. Please check your email and try again.";
    showSnackbar({
      message: "Email verification failed",
      color: "error",
    });
  } finally {
    loading.stop();
    console.log("Email verification completed");
  }
});


const setTimeZone = async () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  try {
    await apiService.post("/users/set-timezone/", { timezone });
    console.log("Timezone set successfully");
  } catch (error) {
    console.error("Error setting timezone:", error);
    // Optionally show a non-blocking error message to the user
  }
};

</script>

<template>
  <div class="misc-wrapper">
    <div class="text-center mb-15">
      <!-- Title and subtitle -->
      <h4 class="text-h4 font-weight-medium mb-2">
        {{ verificationStatus === 'success' ? 'Email Verified 🎉' : 'Email Verification' }}
      </h4>
      <p class="text-body-1 mb-6">
        {{ statusMessage }}
      </p>

      <VBtn v-if="verificationStatus !== 'pending'" to="/">
        Go to dashboard
      </VBtn>
    </div>

    <!-- Image -->
    <div class="misc-avatar w-100 text-center">
      <VImg
        :src="verificationStatus === 'success' ? successImage : errorImage"
        :alt="verificationStatus === 'success' ? 'Success' : 'Error'"
        :max-width="200"
        :min-height="250"
        class="mx-auto"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@core/scss/template/pages/misc.scss";
</style>
