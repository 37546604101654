<script setup>
import ConfigurationsTab from "@/components/campaigns/details/ConfigurationsTab.vue";
import LeadsTab from "@/components/campaigns/details/LeadsTab.vue";
import ScheduleTab from "@/components/campaigns/details/ScheduleTab.vue";
import AnalyticsTab from "@/components/campaigns/details/AnalyticsTab.vue";
import { ref, computed, onMounted, watch } from "vue";
import { useApiService } from "@/common/apiService";
import { useSnackbar } from "@/composables/useSnackbar";
import { useLoadingOverlay } from "@/composables/useLoadingOverlay";
import partyPopper from "@images/illustrations/party-popper.png";
import SampleEmailViewer from "@/components/campaigns/details/SampleEmailViewer.vue";

definePage({
  meta: {
    requiresAuth: true,
    title: "Campaign Details",
  },
});
const route = useRoute("apps-ecommerce-customer-details-id");
const userTab = ref(null);

// Initialize services and composables
const api = useApiService();
const loading = useLoadingOverlay();
const { showSnackbar } = useSnackbar();

// Campaign data state
const campaignData = ref(null);
const analyticsData = ref(null);
const analyticsChartData = ref(null);
const hasCheckedSampleEmails = ref(false);
const skipSampleEmails = ref(false);
const sampleEmails = ref([]);
const sampleEmailsError = ref(null);
const showSampleEmails = ref(false);
const noCampaignData = ref(false);

// Computed properties
const campaignStatus = computed(() => campaignData.value?.status || "unknown");
const campaignStartDate = computed(() => {
  if (campaignData.value?.started_at) {
    return new Date(campaignData.value.started_at).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
    });
  }
  return "Not started";
});

const tabs = [
  {
    title: "Analytics",
    icon: "tabler-chart-histogram",
  },
  {
    title: "Leads",
    icon: "tabler-user-bolt",
  },
  {
    title: "Schedule",
    icon: "tabler-calendar-bolt",
  },
  {
    title: "Configurations",
    icon: "tabler-settings-bolt",
  },
];

const fetchCampaignData = async () => {
  try {
    loading.start("Loading campaign data...");
    const response = await api.get(
      `/campaigns/campaign-detail/${route.params.id}/`
    );
    campaignData.value = response.data;
    hasCheckedSampleEmails.value = response.data.has_checked_sample_emails;
    skipSampleEmails.value = response.data.skip_sample_emails;
    console.log("hasCheckedSampleEmails", hasCheckedSampleEmails.value);
    // console.log("campaignData", campaignData.value);
  } catch (error) {
    console.error("Error fetching campaign data:", error);
    showSnackbar({
      message: "Unable to fetch campaign data. Please try again later",
      color: "error",
      timeout: 5000,
    });
    noCampaignData.value = true;
  } finally {
    loading.stop();
  }
};

const fetchAnalyticsData = async () => {
  try {
    const response = await api.get(
      `/campaigns/campaign-detail/analytics/${route.params.id}/`
    );

    analyticsData.value = response.data.analytics;
    analyticsChartData.value = response.data.chart_data;
  } catch (error) {
    console.error("Error fetching analytics data:", error);
    showSnackbar({
      message: "Unable to fetch analytics data. Please try again later",
      color: "error",
      timeout: 5000,
    });
  } finally {
  }
};

const handleCampaignStatus = () => {
  let status; //pause', 'start'
  if (campaignStatus.value === "active" || campaignStatus.value === "processing") {
    status = "pause";
  } else if (
    campaignStatus.value === "draft" ||
    campaignStatus.value === "paused"
  ) {
    status = "start";
  } else {
    status = campaignStatus.value;
  }
  toggleCampaignStatus(status);
};

const toggleCampaignStatus = async (newStatus) => {
  try {
    loading.start("Updating campaign status...");
    let response = await api.patch(
      `/campaigns/campaign-update/status/${route.params.id}/`,
      {
        action: newStatus,
      }
    );

    await fetchCampaignData(); // Refresh campaign data
    showSnackbar({
      message: response.data.message,
      color: "success",
      timeout: 3000,
    });
  } catch (error) {
    console.error("Error updating campaign status:", error);
    const message =
      error.response?.data?.error || "Error updating campaign status";
    const formattedMessage =
      message.startsWith("['") && message.endsWith("']")
        ? message.slice(2, -2)
        : message;

    showSnackbar({
      message: formattedMessage,
      color: "error",
      timeout: 3000,
    });
  } finally {
    loading.stop();
  }
};

const fetchOtherTabsData = async () => {
  try {
    //
    console.log("fetching other tabs data");
  } catch (error) {
    console.error("Error fetching additional tab data:", error);
    // Optionally notify the user, but don't disrupt the main experience
  }
};

//*********************************************************** SAMPLE EMAIL ACTIONS ****************************************************************/
const skipSamples = async () => {
  try {
    loading.start("Skipping sample emails...");
    const response = await api.patch(
      `/campaigns/skip-sample-emails/${route.params.id}/`
    );
    skipSampleEmails.value = true;
    showSampleEmails.value = false;
  } catch (error) {
    console.error("Error skipping sample emails:", error);
    showSnackbar({
      message: "Failed to skip sample emails. Please try again.",
      color: "error",
    });
    skipSampleEmails.value = true;
  } finally {
    loading.stop();
  }
};

const viewSamples = async () => {
  try {
    loading.start("Fetching sample emails...");
    const response = await api.post(
      `/campaigns/generate-sample-emails/${route.params.id}/`
    );
    sampleEmails.value = response.data;
    showSampleEmails.value = true;
    hasCheckedSampleEmails.value = true;
  } catch (error) {
    sampleEmailsError.value =
      "Failed to fetch sample emails. Please try again.";

    console.error("Error fetching sample emails:", error);
    showSnackbar({
      message: "Failed to load sample emails. Please try again.",
      color: "error",
    });
  } finally {
    loading.stop();
  }
};

const handleLike = (emailId) => {
  // Handle like action
};

const handleDislike = (emailId) => {
  // Handle dislike action
};

const handleEdit = (editedEmail) => {
  // Handle edit action
};

const handleNext = () => {
  // Handle next action (e.g., fetch more emails if needed)
};

const handlePrevious = () => {
  // Handle previous action
};

const handleComplete = () => {
  // Handle completion of email review process
  // For example, navigate to the next step in your campaign creation process
  fetchCampaignData();
  showSampleEmails.value = false;
};

//*********************************************************** END SAMPLE EMAIL ACTIONS **************************************************************/

onMounted(() => {
  fetchCampaignData();
  fetchAnalyticsData();

  // After loading initial data, fetch other tabs' data asynchronously
  fetchOtherTabsData();
});
</script>

<template>
  <div v-if="campaignData">
    <!-- 👉 Header  -->
    <div
      class="d-flex justify-space-between align-center flex-wrap gap-y-4 mb-6"
    >
      <div>
        <h4 class="text-h4 mb-1">{{ campaignData?.name }}</h4>
        <div class="text-body-1">
          <VChip
            :label="campaignStatus"
            :color="campaignStatus === 'active' ? 'success' : 'warning'"
            size="small"
          >
            {{ campaignStatus }}
          </VChip>
          <span class="mx-2">&#8226;</span>

          {{ campaignStartDate }}
        </div>
      </div>
      <div class="d-flex gap-4">
        <!-- button won't be shown for status like completed or processing -->
        <VBtn
          v-if="
            campaignStatus === 'active' ||
            campaignStatus === 'draft' ||
            campaignStatus === 'paused' ||
            campaignStatus === 'processing'
          "
          variant="tonal"
          :color="campaignStatus === 'active' || campaignStatus === 'processing' ? 'error' : 'success'"
          @click="handleCampaignStatus"
        >
          {{
            campaignStatus === 'active' || campaignStatus === 'processing' ? 'Pause Campaign' : 'Activate Campaign'
          }}
        </VBtn>
      </div>
    </div>

    <VRow v-if="hasCheckedSampleEmails || skipSampleEmails">
      <VCol cols="12" v-if="!showSampleEmails">
        <VTabs
          v-model="userTab"
          class="v-tabs-pill mb-3 disable-tab-transition"
        >
          <VTab v-for="tab in tabs" :key="tab.title">
            <VIcon size="20" start :icon="tab.icon" />
            {{ tab.title }}
          </VTab>
        </VTabs>
        <VWindow
          v-model="userTab"
          class="disable-tab-transition"
          :touch="false"
        >
          <VWindowItem>
            <AnalyticsTab
              v-if="analyticsData"
              :campaign-data="campaignData"
              :analytics-data="analyticsData"
              :chart-data="analyticsChartData"
            />
            <div class="center" v-else>
              <VProgressCircular indeterminate :size="50" />
            </div>
          </VWindowItem>
          <VWindowItem>
            <LeadsTab :campaign-data="campaignData" v-if="campaignData" />
            <div class="center" v-else>
              <VProgressCircular indeterminate :size="50" />
            </div>
          </VWindowItem>
          <VWindowItem>
            <ScheduleTab :campaign-data="campaignData" v-if="campaignData" />
            <div class="center" v-else>
              <VProgressCircular indeterminate :size="50" />
            </div>
          </VWindowItem>
          <VWindowItem>
            <ConfigurationsTab
              :campaign-data="campaignData"
              v-if="campaignData"
            />
            <div class="center" v-else>
              <VProgressCircular indeterminate :size="50" />
            </div>
          </VWindowItem>
        </VWindow>
      </VCol>
    </VRow>

    <div v-else>
      <VCol cols="6" sm="12" md="6" class="mx-auto">
        <VCard
          class="sample-email-decision-card"
          elevation="8"
          max-width=""
          rounded="lg"
        >
          <VCardItem>
            <VImg
              :src="partyPopper"
              height="100"
              contain
              class="mx-auto mb-4"
            />

            <VCardTitle class="text-h5 font-weight-bold text-center mb-2">
              Campaign Created Successfully!
            </VCardTitle>

            <VCardSubtitle class="text-body-1 text-center mb-4">
              Would you like to review some AI-generated sample emails for your
              campaign?
            </VCardSubtitle>
          </VCardItem>

          <VDivider />

          <VCardText>
            <p class="text-body-2 text-center mb-4">
              Reviewing samples helps you fine-tune the AI and ensure the emails
              match your style and tone.
            </p>

            <div class="d-flex flex-column gap-3">
              <VBtn
                color="primary"
                variant="elevated"
                block
                @click="viewSamples"
                class="text-capitalize"
              >
                View Sample Emails
                <VIcon end icon="tabler-mail" class="ml-2" />
              </VBtn>

              <VBtn
                variant="tonal"
                block
                @click="skipSamples"
                class="text-capitalize"
              >
                Skip and Continue
                <VIcon end icon="tabler-arrow-right" class="ml-2" />
              </VBtn>
            </div>
          </VCardText>
        </VCard>
      </VCol>
    </div>

    <SampleEmailViewer
      v-if="showSampleEmails"
      :emails="sampleEmails"
      :error="sampleEmailsError"
      @like="handleLike"
      @dislike="handleDislike"
      @edit="handleEdit"
      @next="handleNext"
      @previous="handlePrevious"
      @complete="handleComplete"
      @skipall="skipSamples"
    />
  </div>
  <div v-else-if="noCampaignData">
    <VAlert type="error" class="mt-4">
      Unable to fetch campaign data. Please try again later.
    </VAlert>
  </div>

  <div v-else>
    <div class="center">
      <VProgressCircular indeterminate :size="50" />
    </div>
  </div>

</template>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
