
<script setup>
import { ref, computed, onMounted, watch } from "vue";
import CardGreetings from "@/components/Dashboard/CardGreetings.vue";
import CardStatisticsWeeklyTraffic from "@/components/Dashboard/CardStatisticsWeeklyTraffic.vue";
import CampaingsTable from "@/components/Dashboard/CampaignsTable.vue";
import ProspectsTable from "@/components/Dashboard/ProspectsTable.vue";
import { useTheme } from "vuetify";
import { useAuthStore } from "@/store/auth";
import { useApiService } from "@/common/apiService";
import { useLoadingOverlay } from "@/composables/useLoadingOverlay";
import { useRouter } from "vue-router";

import TourComponent from '@/components/TourComponent.vue'

// Initialize services and composables
const api = useApiService();
const loading = useLoadingOverlay();
const { showSnackbar } = useSnackbar();
const router = useRouter();


definePage({
  meta: {
    requiresAuth: true,
    title: "Dashboard",
    isDashboard: true,
  },
});

const emailStats = ref({
  total_emails_sent: 0,
  total_emails_opened: 0,
  send_trend: [],
  open_trend: []
});

const authStore = useAuthStore()
// Access user data using a computed property
const user = computed(() => authStore.userData)

const vuetifyTheme = useTheme();
const currentTheme = vuetifyTheme.current.value.colors;


const fetchEmailStats = async () => {
  try {
    const response = await api.get("/campaigns/dashboard/email-analytics/");
    emailStats.value = await response.data;
    console.log('Email stats response.data:', response.data);


  } catch (error) {
    console.error('Error fetching email stats:', error);
  }
};

const formatNumber = (num) => {
  if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
  if (num >= 1000) return (num / 1000).toFixed(1) + 'k';
  return num.toString();
};

const calculateOpenRate = () => {
  const { total_emails_sent, total_emails_opened } = emailStats.value;
  if (total_emails_sent === 0) return '0%';
  return ((total_emails_opened / total_emails_sent) * 100).toFixed(1) + '%';
};

const statisticsVertical = computed(() => [
  {
    title: "Emails Delivered",
    color: "primary",
    icon: "tabler-mail",
    stats: formatNumber(emailStats.value.total_emails_sent),
    height: 90,
    series: [
      {
        data: emailStats.value.send_trend,
      },
    ],
    chartOptions: {
      chart: {
        height: 90,
        type: "area",
        toolbar: { show: false },
        sparkline: { enabled: true },
      },
      tooltip: { enabled: false },
      markers: {
        colors: "transparent",
        strokeColors: "transparent",
      },
      grid: { show: false },
      colors: [currentTheme.primary],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.8,
          opacityFrom: 0.6,
          opacityTo: 0.1,
        },
      },
      dataLabels: { enabled: false },
      stroke: {
        width: 2,
        curve: "smooth",
      },
      xaxis: {
        show: true,
        lines: { show: false },
        labels: { show: false },
        stroke: { width: 0 },
        axisBorder: { show: false },
      },
      yaxis: {
        stroke: { width: 0 },
        show: false,
      },
    },
  },
  {
    title: "Emails Opened",
    color: "success",
    icon: "tabler-mail-opened",
    stats: calculateOpenRate(),
    height: 90,
    series: [
      {
        data: emailStats.value.open_trend,
      },
    ],
    chartOptions: {
      chart: {
        height: 90,
        type: "area",
        toolbar: { show: false },
        sparkline: { enabled: true },
      },
      tooltip: { enabled: false },
      markers: {
        colors: "transparent",
        strokeColors: "transparent",
      },
      grid: { show: false },
      colors: [currentTheme.success],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.8,
          opacityFrom: 0.6,
          opacityTo: 0.1,
        },
      },
      dataLabels: { enabled: false },
      stroke: {
        width: 2,
        curve: "smooth",
      },
      xaxis: {
        show: true,
        lines: { show: false },
        labels: { show: false },
        stroke: { width: 0 },
        axisBorder: { show: false },
      },
      yaxis: {
        stroke: { width: 0 },
        show: false,
      },
    },
  },
]);

const viewAllCampaigns = () => {
  router.push({ name: 'campaigns' }); 
};

onMounted(fetchEmailStats);
</script>


<template>

  <VRow class="match-height">
    <TourComponent pageName="index" />
    
    <!-- 👉 Greetings -->
    <VCol cols="12" md="3" lg="4" sm="6">
      <CardGreetings />
    </VCol>

    <!-- 👉 Vertical Cards -->
    <VCol
      v-for="statistics in statisticsVertical"
      :key="statistics.title"
      cols="12"
      sm="4"
      md="4"
      lg="4"
    >
      <CardStatisticsVertical v-bind="statistics" />
    </VCol>

    <!-- 👉 Weekly Traffic -->
    <VCol cols="12">
      <CardStatisticsWeeklyTraffic />
    </VCol>

    <!-- 👉 Campaigns  -->
    <VCol cols="12" lg="6">
      <VCard class="mb-6">
        <VCardItem>
          <template #title>
            <h5 class="text-h5">Email Campaigns</h5>
          </template>
          <template #append>
            <div
            @click="viewAllCampaigns"
              class="text-base font-weight-medium text-primary cursor-pointer"
            >
              View all
            </div>
          </template>
        </VCardItem>

        <CampaingsTable />
      </VCard>
    </VCol>

    <!-- 👉 Prospects  -->
    <VCol cols="12" lg="6">
      <VCard class="mb-6">
        <VCardItem>
          <template #title>
            <h5 class="text-h5">Latest Prospects</h5>
          </template>
        </VCardItem>

        <ProspectsTable />
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss" scoped>
/* stylelint-disable liberty/use-logical-spec */

.card-list {
  --v-card-list-gap: 0.5rem;
}

.current-plan {
  background: linear-gradient(
    45deg,
    rgb(var(--v-theme-primary)) 0%,
    #9e95f5 100%
  );
  color: #fff;
}

.current-plan-column {
  height: 100%;
  margin-top: 100px;
}

.height-100 {
  height: 100% !important;
}
</style>
