<script setup>
import womanCelebrating from "@images/pages/woman-celebrating.png";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useApiService } from "@/common/apiService";
import { useLoadingOverlay } from "@/composables/useLoadingOverlay";
import { useSnackbar } from "@/composables/useSnackbar";

const loading = useLoadingOverlay();
const api = useApiService();
const { showSnackbar } = useSnackbar();

// Page meta
definePage({
  meta: {
    layout: "blank",
    public: false,
    requiresAuth: true,
  },
});

const route = useRoute();
const router = useRouter();

onMounted(() => {
  // Extract token from URL
  const token = route.params.token;
  console.log("Payment success token:", token);

  if (!token) {
    console.error("Payment token not found");
    showSnackbar({
      message: "Payment token not found",
      color: "error",
    });
    router.push("/");
    return;
  }

  try {
    loading.start("Verifying payment token...");
    // Call API to verify payment
    api.post( "/subscriptions/validate-payment-token/", { token });
  } catch (error) {
    console.log("Payment verification failed:", error);
    showSnackbar({
      message: "Payment verification failed",
      color: "error",
    });
  } finally {
    loading.stop();
    console.log("Payment verification completed");
    // Redirect to home page after a short delay
    setTimeout(() => {
      router.push("/");
    }, 1500);
  }
});
</script>

<template>
  <div class="misc-wrapper">
    <div class="text-center mb-15">
      <!-- 👉 Title and subtitle -->
      <h4 class="text-h4 font-weight-medium mb-2">Payment Successful 🎉</h4>
      <p class="text-body-1 mb-6">
        Thank you for your payment. Your transaction has been completed and a
        receipt for your purchase has been emailed to you.
      </p>

      <VBtn to="/"> Go to dashboard </VBtn>
    </div>

    <!-- 👉 Image -->
    <div class="misc-avatar w-100 text-center">
      <VImg
        :src="womanCelebrating"
        alt="Success"
        :max-width="200"
        :min-height="250"
        class="mx-auto"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@core/scss/template/pages/misc.scss";
</style>
