<template>
  <div class="misc-wrapper">
    <VRow align="center" justify="center" style="margin-block: 10rem 0">
      <VCol cols="6">
        <div class="text-center mb-4">
          <h4 class="text-h4 font-weight-medium mb-2">Coming soon 🚀</h4>
          <p>
            AI Phone Calls: Let our AI reach hundreds of potential clients daily on your behalf.
          </p>
          <p class="text-body-1 mb-6">
            Revolutionize your cold calling strategy. Boost client outreach while you focus on closing deals. Stay tuned
          </p>
        </div>

        <div
          class="d-flex flex-wrap align-center justify-center gap-4 misc-form"
        >
          <VBtn 
            v-if="!isSubscribed" 
            type="submit" 
            @click="notifyMe" 
            :loading="isLoading" 
            :disabled="isLoading"
          >
            {{ buttonText }}
          </VBtn>
          <p v-else class="text-success">
            You're already subscribed to receive updates about AI Phone Calls!
          </p>
        </div>
      </VCol>
    </VRow>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useApiService } from '@/common/apiService';
import { useSnackbar } from '@/composables/useSnackbar';


definePage({
  meta: {
    requiresAuth: true,
    title: "Phone Calls",
  },
});

const api = useApiService();
const { showSnackbar } = useSnackbar();

const isLoading = ref(false);
const isSubscribed = ref(false);
const buttonText = ref('Notify me');

const checkSubscriptionStatus = async () => {
  try {
    const response = await api.get('/users/notify-launch/');
    const subscriptions = response.data;
    isSubscribed.value = subscriptions.some(sub => sub.action === 'ai_phone_calls');
  } catch (error) {
    console.error('Error checking subscription status:', error);
    showSnackbar({
      message: 'Failed to check notification status. Please try again later.',
      color: 'error',
      timeout: 5000,
    });
  }
};

const notifyMe = async () => {
  if (isSubscribed.value) return;

  isLoading.value = true;
  try {
    await api.post('/users/notify-launch/', {
      action: 'ai_phone_calls'
    });

    showSnackbar({
      message: 'You will be notified when AI Phone Calls feature launches!',
      color: 'success',
      timeout: 5000,
    });

    isSubscribed.value = true;
    buttonText.value = 'Notified';
  } catch (error) {
    console.error('Error sending notification request:', error);
    showSnackbar({
      message: 'Failed to set up notification. Please try again later.',
      color: 'error',
      timeout: 5000,
    });
  } finally {
    isLoading.value = false;
  }
};

onMounted(checkSubscriptionStatus);
</script>
