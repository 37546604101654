<script setup>
import { ref } from 'vue'
import { useGenerateImageVariant } from '@core/composable/useGenerateImageVariant'
import authV2ForgotPasswordIllustrationDark from '@images/pages/brain.png'
import authV2ForgotPasswordIllustrationLight from '@images/pages/brain.png'
import authV2MaskDark from '@images/pages/misc-mask-dark.png'
import authV2MaskLight from '@images/pages/misc-mask-light.png'
import { VNodeRenderer } from '@layouts/components/VNodeRenderer'
import { themeConfig } from '@themeConfig'
import { useApiService } from "@/common/apiService"
import { useLoadingOverlay } from '@/composables/useLoadingOverlay'
import { useSnackbar } from '@/composables/useSnackbar'

definePage({
  meta: {
    requiresAuth: false,
    title: "Forgot Password",
    layout: "blank",
  },
});

const email = ref('')
const authThemeImg = useGenerateImageVariant(authV2ForgotPasswordIllustrationLight, authV2ForgotPasswordIllustrationDark)
const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark)

const apiService = useApiService()
const loading = useLoadingOverlay()
const { showSnackbar } = useSnackbar()

const isSubmitting = ref(false)
const resetSent = ref(false)

const handleSubmit = async () => {
  if (!email.value) {
    showSnackbar({
      message: 'Please enter your email address',
      color: 'error',
    })
    return
  }

  isSubmitting.value = true
  loading.start('Sending reset link...')

  try {
    const response = await apiService.post('/users/password-reset/', { email: email.value })
    resetSent.value = true
    showSnackbar({
      message: 'Password reset link sent to your email',
      color: 'success',
    })
  } catch (error) {
    showSnackbar({
      message: error.response?.data?.detail || 'An error occurred. Please try again.',
      color: 'error',
    })
  } finally {
    isSubmitting.value = false
    loading.stop()
  }
}
</script>

<template>
  <RouterLink to="/">
    <div class="auth-logo d-flex align-center gap-x-3">
      <VNodeRenderer :nodes="themeConfig.app.logo" />
      <h1 class="auth-title">
        {{ themeConfig.app.title }}
      </h1>
    </div>
  </RouterLink>

  <VRow
    class="auth-wrapper bg-surface"
    no-gutters
  >
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background w-100 me-0">
        <div
          class="d-flex align-center justify-center w-100 h-100"
          style="padding-inline: 150px;"
        >
          <VImg
            max-width="468"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2 flip-in-rtl"
          />
        </div>

        <img
          class="auth-footer-mask flip-in-rtl"
          :src="authThemeMask"
          alt="auth-footer-mask"
          height="280"
          width="100"
        >
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-6"
      >
        <VCardText>
          <h4 class="text-h4 mb-1">
            Forgot Password? 🔒
          </h4>
          <p class="mb-0">
            Enter your email and we'll send you instructions to reset your password
          </p>
        </VCardText>

        <VCardText>
          <VForm @submit.prevent="() => {}">
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
            v-model="email"
            autofocus
            label="Email"
            placeholder="johndoe@email.com"
            type="email"
            :disabled="isSubmitting || resetSent"
          />
              </VCol>

              <!-- Reset link -->
              <VCol cols="12">
          <VBtn
            block
            type="submit"
            :loading="isSubmitting"
            :disabled="resetSent"
            @click="handleSubmit"
          >
            {{ resetSent ? 'Reset Link Sent' : 'Send Reset Link' }}
          </VBtn>
        </VCol>

              <!-- back to login -->
              <VCol cols="12">
                <RouterLink
                  class="d-flex align-center justify-center"
                  to="/login"
                >
                  <VIcon
                    icon="tabler-chevron-left"
                    size="20"
                    class="me-1 flip-in-rtl"
                  />
                  <span>Back to login</span>
                </RouterLink>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>


<style>
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.auth-illustration {
  animation: floatAnimation 3s ease-in-out infinite;
}
</style>
