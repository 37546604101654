<script setup>
import { ref } from "vue";
import { useApiService } from "@/common/apiService";
import { useRouter } from "vue-router";
import { useLoadingOverlay } from "@/composables/useLoadingOverlay";
import { useSnackbar } from "@/composables/useSnackbar";
import serverImage from "@images/illustrations/server.png";

definePage({
  meta: {
    requiresAuth: true,
    title: "Email Accounts - SMTP",
  },
});
const apiService = useApiService();
const router = useRouter();
const loading = useLoadingOverlay();
const snackbar = useSnackbar();

const firstName = ref("");
const lastName = ref("");
const email = ref("");
const password = ref("");
const smtpHost = ref("");
const smtpPort = ref("587");
const enableSSL = ref(false);

const errors = ref({});

const validateForm = () => {
  errors.value = {};

  if (!firstName.value) errors.value.firstName = "First name is required";
  if (!lastName.value) errors.value.lastName = "Last name is required";
  if (!email.value) errors.value.email = "Email is required";
  else if (!/^\S+@\S+\.\S+$/.test(email.value))
    errors.value.email = "Invalid email format";
  if (!password.value) errors.value.password = "Password is required";
  if (!smtpHost.value) errors.value.smtpHost = "SMTP Host is required";
  if (!smtpPort.value) errors.value.smtpPort = "SMTP Port is required";
  else if (!/^\d+$/.test(smtpPort.value))
    errors.value.smtpPort = "SMTP Port must be a number";

  return Object.keys(errors.value).length === 0;
};

const handleSubmit = async () => {
  if (!validateForm()) return;

  loading.start("Connecting email account...");

  try {
    const response = await apiService.post("/campaigns/email-accounts/", {
      sender_first_name: firstName.value,
      sender_last_name: lastName.value,
      email: email.value,
      password: password.value,
      smtp_host: smtpHost.value,
      smtp_port: parseInt(smtpPort.value),
      use_ssl_tls: enableSSL.value,
      provider: "smtp",
    });

    snackbar.showSnackbar({
      message: "Email account connected successfully!",
      color: "success",
    });

    router.push("/email-accounts");
  } catch (error) {
    console.error("Error connecting email account:", error);
    // console.log(error.response.data);
    // console.log(error.response.data.error);
    if(error.response.status === 500){
      snackbar.showSnackbar({
        message: "An error occurred while connecting email account. Please try again.",
        color: "error",
      });
    }

    let errorMessage = "Failed to connect email account. Please try again."
    errors.value = {}

    if (error.response?.data) {
      const errorData = error.response.data

      if (typeof errorData === 'string') {
        // Handle single string error
        errorMessage = errorData
      } else if (errorData.error && typeof errorData.error === 'string') {
        // Handle single string error in 'error' field
        errorMessage = errorData.error
      } else if (typeof errorData === 'object') {
        // Handle object with multiple field errors
        Object.entries(errorData).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            errors.value[key] = value[0]  // Take the first error message for each field
          } else if (typeof value === 'string') {
            errors.value[key] = value
          }
        })
        
        // Set error message to the first error encountered
        errorMessage = Object.values(errors.value)[0] || errorMessage
      }
    }

    snackbar.showSnackbar({
      message: errorMessage,
      color: "error"
    })
  } finally {
    loading.stop();
  }
};
</script>

<template>
  <div>
    <VRow justify="center" style="margin-block: 10rem 0">
      <VCol cols="12">
        <VCard class="mb-6">
          <VCardText class="py-12 position-relative">
            <div
              class="d-flex flex-column gap-y-4 mx-auto"
              :class="
                $vuetify.display.mdAndUp
                  ? 'w-50'
                  : $vuetify.display.xs
                  ? 'w-100'
                  : 'w-75'
              "
            >
              <div class="align-self-center">
                <img :src="serverImage" height="90" class="flip-in-rtl" />
              </div>

              <h4
                class="text-h4 text-center text-wrap mx-auto"
                :class="$vuetify.display.mdAndUp ? 'w-75' : 'w-100'"
              >
                Connect via SMTP
              </h4>
              <p class="text-center text-wrap text-body-1 mx-auto mb-0">
                Fill in the details below to connect your email account.
              </p>

              <VForm @submit.prevent="handleSubmit">
                <VRow>
                  <VCol cols="12" md="6">
                    <AppTextField
                      v-model="firstName"
                      label="First Name"
                      placeholder="John"
                      :error-messages="errors.firstName"
                    />
                  </VCol>

                  <VCol cols="12" md="6">
                    <AppTextField
                      v-model="lastName"
                      label="Last Name"
                      placeholder="Doe"
                      :error-messages="errors.lastName"
                    />
                  </VCol>

                  <VCol cols="12" md="6">
                    <AppTextField
                      v-model="email"
                      label="Email"
                      placeholder="johndoe@email.com"
                      :error-messages="errors.email"
                    />
                  </VCol>

                  <VCol cols="12" md="6">
                    <AppTextField
                      v-model="password"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      :error-messages="errors.password"
                    />
                  </VCol>

                  <VCol cols="12" md="6">
                    <AppTextField
                      v-model="smtpHost"
                      label="SMTP Host"
                      placeholder="SMTP Host"
                      :error-messages="errors.smtpHost"
                    />
                  </VCol>

                  <VCol cols="12" md="6">
                    <AppTextField
                      v-model="smtpPort"
                      label="SMTP Port"
                      placeholder="SMTP Port"
                      :error-messages="errors.smtpPort"
                    />
                  </VCol>

                  <VCol cols="12">
                    <VCheckbox v-model="enableSSL" label="Enable SSL/TLS" />
                  </VCol>
                </VRow>

                <div class="d-flex flex-wrap gap-4 justify-center mt-6">
                  <VBtn
                    color="secondary"
                    variant="tonal"
                    :to="'/email-accounts'"
                    >Cancel</VBtn
                  >
                  <VBtn type="submit" variant="outlined">Connect</VBtn>
                </div>
              </VForm>
            </div>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </div>
</template>
