import { createApp } from "vue";
import App from "@/App.vue";
import { registerPlugins } from "@core/utils/plugins";
import * as Sentry from "@sentry/vue";
import { router } from "./plugins/1.router";
import { inject } from '@vercel/analytics';

// Styles
import "@core/scss/template/index.scss";
import "@styles/styles.scss";

const VITE_ENVIROMENT = import.meta.env.VITE_ENVIROMENT;
if (VITE_ENVIROMENT === "production") {
  console.log = function () {}; // Disable console.log in production
  console.error = function () {}; // Disable console.error in production
  console.table = function () {}; // Disable console.table in production
}

// Create vue app
const app = createApp(App);
// const API_BASE_URL =  process.env.VUE_APP_API_BASE_URL
const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

console.log("API_BASE_URL:", API_BASE_URL);
console.log("NODE_ENV:", process.env.NODE_ENV);
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://036988ac934185219cb3c7a354bb8059@o4507132267986944.ingest.us.sentry.io/4507964040740864",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", API_BASE_URL],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // enabled: VITE_ENVIROMENT !== "development",
    // enable_tracing: VITE_ENVIROMENT !== "development",
  });

  // Inject Vercel Analytics
  inject();
}

// Register plugins
registerPlugins(app);

// Mount vue app
app.mount("#app");
