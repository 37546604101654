import { useAuthStore } from '@/store/auth'
import { useRouter } from 'vue-router'

export default function logoutHandler() {
  const authStore = useAuthStore()
  const router = useRouter()

  // Clear auth store
  authStore.clearAuthData()

  // Clear any additional application state if necessary
  // For example, if you're using Pinia for other stores:
  // useUserStore().$reset()
  // useSettingsStore().$reset()

  // Clear any application data from localStorage
//   localStorage.clear()

  // Redirect to login page
  router.push({ name: 'login' })
}
