import { useAuthStore } from "@/store/auth";
import { setupLayouts } from "virtual:generated-layouts";
import { createRouter, createWebHistory } from "vue-router/auto";
import logoutHandler from "./logout";
import { redirects, routes } from './additional-routes'

function recursiveLayouts(route) {
  if (route.children) {
    for (let i = 0; i < route.children.length; i++)
      route.children[i] = recursiveLayouts(route.children[i]);

    return route;
  }

  return setupLayouts([route])[0];
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to) {
    if (to.hash) return { el: to.hash, behavior: "smooth", top: 60 };

    return { top: 0 };
  },
  extendRoutes: (pages) => [
    ...redirects,
    ...[...pages,...routes,].map((route) => recursiveLayouts(route)),

    {
      path: "/logout",
      name: "logout",
      beforeEnter: (to, from, next) => {
        logoutHandler();
        next(false); // Prevent the navigation to /logout
      },
    },
  ],
});

const DEFAULT_TITLE = "LeaduxAI";

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  if (!to.matched.length) {
    next("/notFound");
    return;
  }
  // console.log("meta route: ",to.meta);

  if (to.meta.requiresAuth) {
    if (!authStore.isAuthenticated) {
      const storedAuth = JSON.parse(localStorage.getItem("authData") || "{}");

      if (storedAuth.accessToken) {
        try {
          await authStore.restoreSession(storedAuth);
          // If successful, allow navigation
          document.title = to.meta.title
            ? `${to.meta.title} | LeaduxAI`
            : DEFAULT_TITLE;
          next();
        } catch (error) {
          // If restoration fails, redirect to login
          next({
            name: "login",
            query: { redirect: to.fullPath },
          });
        }
      } else {
        // No token in localStorage, redirect to login
        next({
          name: "login",
          query: { redirect: to.fullPath },
        });
      }
    } else {
      // Already authenticated, allow navigation
      document.title = to.meta.title
        ? `${to.meta.title} | LeaduxAI`
        : DEFAULT_TITLE;
      next();
    }
  } else {
    // Route doesn't require auth, allow navigation
    document.title = to.meta.title
      ? `${to.meta.title} | LeaduxAI`
      : DEFAULT_TITLE;
    next();
  }
});
export { router };

export default function (app) {
  app.use(router);
}
