<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useApiService } from "@/common/apiService";
import TourComponent from '@/components/TourComponent.vue'
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import CampaignOnboardVideo from '@videos/create-campaign-tutorial.mp4'
import BlogImg from '@images/pages/campaign-img.jpg'

  
definePage({
  meta: {
    requiresAuth: true,
    title: "Campaigns",
  },
});
const apiService = useApiService()

// 👉 Store
const searchQuery = ref("");

// Data table options
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref('created_at');
const orderBy = ref('desc');

// todo implement sorting at the backend

// Campaign data
const campaignData = ref({
  count: 0,
  next: null,
  previous: null,
  results: [],
});

const loading = ref(false);

const updateOptions = (options) => {
  sortBy.value = options.sortBy[0]?.key || 'created_at';
  orderBy.value = options.sortBy[0]?.order || 'desc';
  fetchCampaigns();
};

// Headers
const headers = [
  { title: "#", key: "index" },
  { title: "Campaign Name", key: "name", sortable: true },
  { title: "Sent", key: "sent_count", sortable: true },
  { title: "Leads", key: "leads_count", sortable: true },
  { title: "Leads Progress", key: "leads_progress" },
  { title: "AI reply enabled", key: "ai_reply_enabled", sortable: false },
  { title: "Type", key: "type" },
  { title: "Status", key: "status", sortable: true },
  { title: "Created at", key: "created_at", sortable: true },
];

const campaigns = computed(() => campaignData.value.results);
const totalCampaigns = computed(() => campaignData.value.count);

const status = [
  { title: "Draft", value: "draft" },
  { title: "Active", value: "active" },
  { title: "Completed", value: "completed" },
];

const resolveUserStatusVariant = (stat) => {
  const statLowerCase = stat.toLowerCase();
  if (statLowerCase === "draft" || statLowerCase === "paused" ) return "warning";
  if (statLowerCase === "active") return "success";
  if (statLowerCase === "completed") return "info";
  return "primary";
};

const fetchCampaigns = async () => {
  loading.value = true;
  try {
    const response = await apiService.get('/campaigns/campaign-list/', {
      params: {
        page: page.value,
        per_page: itemsPerPage.value,
        search: searchQuery.value,
        sort_by: sortBy.value,
        order_by: orderBy.value,
      }
    });
    campaignData.value = response.data;
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    // Handle error (e.g., show error message to user)
  } finally {
    loading.value = false;
  }
};

// Watch for changes in search query, page, or items per page
watch([searchQuery, page, itemsPerPage], () => {
  fetchCampaigns();
});

onMounted(() => {
  fetchCampaigns();
});
</script>


<template>
  <TourComponent pageName="campaigns" />

  <div>
    <VRow class="py-6">
      <VCol cols="12">
        <div
          class="d-flex flex-wrap justify-start justify-sm-space-between gap-y-4 gap-x-6 mb-6"
        >
          <div class="d-flex flex-column justify-center">
            <h4 class="text-h4 font-weight-medium">Campaigns</h4>
            <div class="text-wrap text-body-1" style="max-inline-size: 360px">
              You can find your campaigns here. You can create, edit, and delete campaigns.
            </div>
          </div>

          <div class="d-flex gap-4 align-center flex-wrap">
            <VBtn prepend-icon="tabler-plus" :to="'campaigns/create'">
              New Campaign
            </VBtn>
          </div>
        </div>
      </VCol>
    </VRow>

    <VCard class="mb-6">
      <VCardText class="d-flex flex-wrap gap-6">
        <div class="app-user-search-filter d-flex align-center flex-wrap gap-6">
          <div style="inline-size: 15.625rem">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search Campaigns"
            />
          </div>
        </div>
      </VCardText>

          <!-- SECTION datatable -->
    <VDataTableServer
      v-model:items-per-page="itemsPerPage"
      v-model:page="page"
      :items="campaigns"
      :items-length="totalCampaigns"
      :headers="headers"
      :loading="loading"
      class="text-no-wrap campaigns-list"
      id="campaigns-list"
      @update:options="updateOptions"
    >
      <template #item.index="{ index }">
        {{ index + 1 }}
      </template>

      <!-- Campaign Name -->
      <template #item.name="{ item }">
        <div class="d-flex align-center gap-x-4">
          <div class="d-flex flex-column">
            <h6 class="text-base">
              <RouterLink
                :to="`/campaigns/${item.uuid}`"
                class="font-weight-large text-link"
              >
                {{ item.name }}
              </RouterLink>
            </h6>
          </div>
        </div>
      </template>

      <!-- created_at -->
      <template #item.created_at="{ item }">
        <div class="text-body-1 text-high-emphasis text-capitalize">
          {{ new Date(item.created_at).toLocaleString() }}
        </div>
      </template>

      <!-- type -->
      <template #item.type="{ item }">
        <div class="text-body-1 text-high-emphasis text-capitalize">
          {{ item.type }}
        </div>
      </template>

      <!-- leads_progress -->
      <template #item.leads_progress="{ item }">
        <VProgressCircular
                :size="40"
                :width="3"
                :model-value="item.leads_progress"
                :color="item.leads_progress > 0 ? 'primary' : 'grey'"
              >
                {{ item.leads_progress.toFixed(0) }}%
              </VProgressCircular>
      </template>

      <!-- ai_reply_enabled -->
      <template #item.ai_reply_enabled="{ item }">
        <VIcon>
          {{ item.ai_reply_enabled ? 'tabler-circle-check-filled' : 'tabler-circle-x-filled' }}
        </VIcon>
      </template>

      <!-- Status -->
      <template #item.status="{ item }" class="align-center vertical-center">
        <VChip
          :color="resolveUserStatusVariant(item.status)"
          size="small"
          label
          class="text-capitalize"
        >
          {{ item.status }}
        </VChip>
      </template>

      <!-- pagination -->
      <template #bottom>
        <!-- todo Pagination does not work -->
        <TablePagination
          v-model:page="page"
          :items-per-page="itemsPerPage"
          :total-items="totalCampaigns"
        />
      </template>
    </VDataTableServer>

    </VCard>


    <VCard>
      <VCardText>
        <VRow>
          <VCol
            cols="12"
            md="4"
          >
            <div class="d-flex flex-column align-center gap-y-4 h-100 justify-center">
              <VAvatar
                variant="tonal"
                size="52"
                rounded
                color="primary"
              >
                <VIcon
                  icon="tabler-help-hexagon"
                  size="36"
                />
              </VAvatar>
              <h4 class="text-h4 font-weight-medium">
                Need Help?
              </h4>
              <p class="text-body-1 text-center mb-0">
                If you need help with creating a campaign, you can check out our tutorial video or blog post.
              </p>
              <VBtn
                color="primary"
                variant="tonal"
                href="mailto:support@leaduxai.io?subject=Need%20Help&body=Hello I%20would%20like%20to%20get%20in%20touch%20with%20LeaduxAI."
                >
                Get in touch
              </VBtn>
            </div>
          </VCol>
          <VCol
            cols="12"
             md="4"
            sm="6"
          >
            <VCard
              flat
              border
            >
              <div class="px-2 pt-2">
                <video-player
                    class="video-js vjs-big-play-centered w-100 onboarding-video"       
                    :sources="[{ src: CampaignOnboardVideo, type: 'video/mp4' }]"
                    controls
                    :loop="true"
                    :volume="0.6"
                    width="400px"
                    height="200px"
                />
              </div>
              <VCardText>
                <h5 class="text-h5 mb-2">
                  Create Your First Campaign
                </h5>
                <p class="text-body-1 mb-0">
                  Our quick tutorial video walks you through creating your first campaign in just minutes.
                </p>
              </VCardText>
            </VCard>
          </VCol>
          
           <VCol
            cols="12"
             md="4"
            sm="6"
          >
            <VCard
              flat
              border
            >
              <div class="px-2 pt-2">
                <a href="https://www.leaduxai.io/resources/create-campaign-tutorial" target="_blank">
                  <VImg
                     width="400px" height="200px"
                    :src="BlogImg"
                    class="tut-img"
                  />
                </a>
              </div>
              <VCardText>
                <h5 class="text-h5 mb-2">
                    <a href="https://www.leaduxai.io/resources/create-campaign-tutorial" target="_blank">
                      Create Your First Campaign
                    </a>
                </h5>
                <p class="text-body-1 mb-0">
                  Our comprehensive blog post guides you through every step of creating a powerful AI-driven campaign. 
                </p>
              </VCardText>
            </VCard>
          </VCol>
          
        </VRow>
      </VCardText>
    </VCard>

  </div>
</template>

<style>
.v-data-table-header__content {
  text-transform: capitalize !important;
}

.v-data-table :deep(td) {
  vertical-align: middle;
}
</style>


<style>
.v-data-table-header__content {
  text-transform: capitalize !important;
}

.v-table > hr {
  display: none;
}

.v-progress-circular__content {
  font-size: 0.7rem;
}

.onboarding-video{
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

.tut-img{
  cursor: pointer;
}
</style>

<style>
.tut-img > .v-img__img{
    left: 15px !important;
    object-fit: cover !important;
}
</style>
