// src/composables/useSnackbar.js
import { ref, provide, inject } from 'vue'

const snackbarKey = Symbol('snackbar')

export function provideSnackbar() {
  const isVisible = ref(false)
  const message = ref('')
  const color = ref('default')
  const timeout = ref(5000)

  const showSnackbar = (options) => {
    message.value = options.message
    color.value = options.color || 'default'
    timeout.value = options.timeout || 5000
    isVisible.value = true
  }

  const hideSnackbar = () => {
    isVisible.value = false
  }

  provide(snackbarKey, {
    isVisible,
    message,
    color,
    timeout,
    showSnackbar,
    hideSnackbar,
  })
}

export function useSnackbar() {
  const snackbar = inject(snackbarKey)
  if (!snackbar) {
    throw new Error('Snackbar is not provided. Did you forget to call provideSnackbar?')
  }
  return snackbar
}
