<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useApiService } from "@/common/apiService";
import { useSnackbar } from "@/composables/useSnackbar";
import { useLoadingOverlay } from "@/composables/useLoadingOverlay";
import TourComponent from '@/components/TourComponent.vue'
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import EmailOnboardVideo from '@videos/add-email-account.mp4'
import EmailImg from '@images/pages/email-account.jpg'

definePage({
  meta: {
    requiresAuth: true,
    title: "Email Accounts",
  },
});
const loading = useLoadingOverlay();
const { showSnackbar } = useSnackbar();

const apiService = useApiService();

// 👉 Store
const searchQuery = ref("");

// Data table options
const itemsPerPage = ref(10);
const page = ref(1);
const sortBy = ref("email");
const orderBy = ref("asc");
const editDialog = ref(false);
const deleteDialog = ref(false);
const editedItem = ref({});

const accountsData = ref({
  count: 0,
  next: null,
  previous: null,
  results: [],
});

const isLoading = ref(false);

const updateOptions = (options) => {
  sortBy.value = options.sortBy[0]?.key || "email";
  orderBy.value = options.sortBy[0]?.order || "asc";
  fetchEmailAccounts();
};

// Headers
const headers = [
  { title: "#", key: "index" },
  { title: "Email", key: "email", sortable: true },
  { title: "Provider", key: "provider", sortable: true },
  { title: "Daily Sending Limit", key: "daily_sending_limit", sortable: true },
  { title: "Status", key: "status", sortable: true },
  { title: "Actions", key: "actions", sortable: false },
];

const email_accounts = computed(() => accountsData.value.results);
const totalAccounts = computed(() => accountsData.value.count);

const resolveUserStatusVariant = (stat) => {
  const statLowerCase = stat.toLowerCase();
  if (statLowerCase === "active") return "success";
  if (statLowerCase === "inactive") return "error";
  return "primary";
};

const fetchEmailAccounts = async () => {
  isLoading.value = true;
  try {
    const response = await apiService.get("/campaigns/email-accounts/", {
      params: {
        page: page.value,
        per_page: itemsPerPage.value,
        search: searchQuery.value,
        sort_by: sortBy.value,
        order_by: orderBy.value,
      },
    });
    accountsData.value = response.data;
  } catch (error) {
    console.error("Error fetching email accounts:", error);
    // Handle error (e.g., show error message to user)
  } finally {
    isLoading.value = false;
  }
};

const editItem = (item) => {
  editedItem.value = { ...item };
  editDialog.value = true;
};

const deleteItem = (item) => {
  editedItem.value = { ...item };
  deleteDialog.value = true;
};

const close = () => {
  editDialog.value = false;
  editedItem.value = {};
};

const closeDelete = () => {
  deleteDialog.value = false;
  editedItem.value = {};
};

const save = async () => {
  try {
    loading.start("Updating email account...");
    await apiService.put(
      `/campaigns/email-accounts/${editedItem.value.uuid}/`,
      editedItem.value
    );
    close();
    fetchEmailAccounts();

    showSnackbar({
      message: "Email account updated successfully.",
      color: "default",
      timeout: 3000,
    });
  } catch (error) {
    console.error("Error updating email account:", error);

    let errorMessage =
      "Error updating campaign configuration. Please try again.";

    if (error.response && error.response.data) {
      const firstErrorKey = Object.keys(error.response.data)[0];
      if (firstErrorKey) {
        const fieldName = getFieldDisplayName(firstErrorKey);
        const errorDetail = error.response.data[firstErrorKey][0];
        errorMessage = `${fieldName}: ${errorDetail}`;
      }
    }

    showSnackbar({
      message: errorMessage,
      color: "error",
      timeout: 5000,
    });
  } finally {
    loading.stop();
  }
};

// Helper function to get a display-friendly field name
const getFieldDisplayName = (fieldName) => {
  const fieldMap = {
    name: "Campaign Name",
    email_accounts: "Email Accounts",
    send_limit_per_day: "Daily Send Limit",
    include_unsubscribe_link: "Unsubscribe Link",
    track_email_opens: "Track Email Opens",
    ai_reply_enabled: "AI Replies",
    send_as_text_only: "Send as Text Only",
    sequence_choice: "Email Sequence",
    manual_email_count: "Number of Follow-ups",
  };

  return (
    fieldMap[fieldName] ||
    fieldName.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())
  );
};

const deleteItemConfirm = async () => {
  try {
    await apiService.delete(
      `/campaigns/email-accounts/${editedItem.value.uuid}/`
    );
    closeDelete();
    fetchEmailAccounts();
    showSnackbar({
      message: "Email account deleted successfully.",
      color: "default",
      timeout: 3000,
    });
  } catch (error) {
    console.error("Error deleting email account:", error);
    showSnackbar({
      message: "Error deleting email account.",
      color: "error",
      timeout: 3000,
    });
    // Handle error (e.g., show error message to user)
  }
};

// Watch for changes in search query, page, or items per page
watch([searchQuery, page, itemsPerPage], () => {
  fetchEmailAccounts();
});

onMounted(() => {
  fetchEmailAccounts();
});
</script>

<template>
  <div>
  <TourComponent pageName="email-accounts" />

    <VRow class="py-6">
      <VCol cols="12">
        <div
          class="d-flex flex-wrap justify-start justify-sm-space-between gap-y-4 gap-x-6 mb-6"
        >
          <div class="d-flex flex-column justify-center">
            <h4 class="text-h4 font-weight-medium">Email Accounts</h4>
            <div class="text-wrap text-body-1" style="max-inline-size: 360px">
              Start by adding your email accounts to begin your campaign.
            </div>
          </div>

          <div class="d-flex gap-4 align-center flex-wrap">
            <VBtn prepend-icon="tabler-plus" :to="'email-accounts/connect'">
              Add Email Account
            </VBtn>
          </div>
        </div>
      </VCol>
    </VRow>

    <VCard class="mb-6">
      <VCardText class="d-flex flex-wrap gap-6">
        <div class="app-user-search-filter d-flex align-center flex-wrap gap-6">
          <div style="inline-size: 15.625rem">
            <AppTextField
              v-model="searchQuery"
              placeholder="Search Email Accounts"
            />
          </div>
        </div>
      </VCardText>

      <!-- SECTION datatable -->
      <VDataTableServer
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="email_accounts"
        :items-length="totalAccounts"
        :headers="headers"
        :loading="isLoading"
        class="text-no-wrap"
        @update:options="updateOptions"
      >
        <template #item.index="{ index }">
          {{ index + 1 }}
        </template>

        <!-- email -->
        <template #item.email="{ item }">
          <div class="d-flex align-center gap-x-4">
            <div class="d-flex flex-column">
              <h6 class="text-base">
                {{ item.email }}
              </h6>
            </div>
          </div>
        </template>

        <template #item.provider="{ item }">
          <div style="text-transform: uppercase">
            {{ item.provider }}
          </div>
        </template>
        <!-- Status -->
        <template #item.status="{ item }">
          <VChip
            :color="resolveUserStatusVariant(item.status)"
            size="small"
            label
            class="text-capitalize"
          >
            {{ item.status }}
          </VChip>
        </template>

        <template #item.actions="{ item }">
          <div class="d-flex gap-1">
            <IconBtn @click="editItem(item)">
              <VIcon icon="tabler-edit" />
            </IconBtn>
            <IconBtn @click="deleteItem(item)">
              <VIcon icon="tabler-trash" />
            </IconBtn>
          </div>
        </template>
        <!-- todo add total sent_count -->

        <!-- pagination -->
        <template #bottom>
          <TablePagination
            v-model:page="page"
            :items-per-page="itemsPerPage"
            :total-items="totalAccounts"
          />
        </template>
      </VDataTableServer>
    </VCard>

    <!-- 👉 Edit Dialog  -->
    <VDialog
      v-model="editDialog"
      :width="$vuetify.display.smAndDown ? 'auto' : 900"
    >
      <VCard>
        <VCardTitle>
          <span class="headline">Email Account Settings</span>
        </VCardTitle>

        <VCardText>
          <h4 class="mb-5">{{ editedItem.email }}</h4>
          <VRow>
            <VCol cols="12" sm="6" md="6">
              <AppTextField
                v-model="editedItem.sender_first_name"
                label="Sender First Name"
                placeholder="Sender First Name"
              />
            </VCol>

            <VCol cols="12" sm="6" md="6">
              <AppTextField
                v-model="editedItem.sender_last_name"
                label="Sender Last Name"
                placeholder="Sender Last Name"
              />
            </VCol>

            <VCol cols="12" sm="6" md="6">
              <AppTextField
                v-model.number="editedItem.daily_sending_limit"
                label="Daily sending limit (min: 100)"
                type="number"
                placeholder="Daily sending limit"
              />
            </VCol>

            <VCol cols="12" sm="6" md="6">
              <AppTextField
                v-model="editedItem.reply_to"
                label="Reply to"
                prepend-inner-icon="tabler-mail"
                placeholder="email@example.com"
                persistent-placeholder
              />
            </VCol>
          </VRow>
        </VCardText>

        <VCardActions>
          <VSpacer />
          <VBtn color="secondary" variant="tonal" @click="close"> Cancel </VBtn>
          <VBtn color="primary" variant="elevated" @click="save"> Save </VBtn>
          <VSpacer />
        </VCardActions>
      </VCard>
    </VDialog>

    <!-- 👉 Delete Dialog  -->
    <VDialog v-model="deleteDialog" max-width="900px">
      <VCard>
        <VCardTitle>Delete Email Account </VCardTitle>
        <VCardText>
          <div class="d-flex justify-center">
            Everything connected with this Email Address will be deleted.
            <br /> Any campaigns using this email account will be affected.

            <br />
            <br />
            Are you sure you want to proceed?
          </div>
        </VCardText>

        <VCardActions>
          <VSpacer />
          <VBtn color="secondary" variant="tonal" @click="closeDelete">
            Cancel
          </VBtn>
          <VBtn color="error" variant="elevated" @click="deleteItemConfirm">
            Delete
          </VBtn>
          <VSpacer />
        </VCardActions>
      </VCard>
    </VDialog>

    <VCard>
      <VCardText>
        <VRow>
          <VCol
            cols="12"
            md="4"
          >
            <div class="d-flex flex-column align-center gap-y-4 h-100 justify-center">
              <VAvatar
                variant="tonal"
                size="52"
                rounded
                color="primary"
              >
                <VIcon
                  icon="tabler-help-hexagon"
                  size="36"
                />
              </VAvatar>
              <h4 class="text-h4 font-weight-medium">
                Need Help?
              </h4>
              <p class="text-body-1 text-center mb-0">
                If you need help with adding an email account,  you can check out our tutorial video or blog post.
              </p>
              <VBtn
                color="primary"
                variant="tonal"
                href="mailto:support@leaduxai.io?subject=Need%20Help&body=Hello I%20would%20like%20to%20get%20in%20touch%20with%20LeaduxAI."
                >
                Get in touch
              </VBtn>
            </div>
          </VCol>
          <VCol
            cols="12"
            md="4"
            sm="6"
          >
            <VCard
              flat
              border
            >
              <div class="px-2 pt-2">
                <video-player
                    class="video-js vjs-big-play-centered w-100 onboarding-video"       
                    :sources="[{ src: EmailOnboardVideo, type: 'video/mp4' }]"
                    controls
                    :loop="true"
                    :volume="0.6"
                    width="400px"
                    height="200px"
                />
              </div>
              <VCardText>
                <h5 class="text-h5 mb-2">
                  Add an Email Account
                </h5>
                <p class="text-body-1 mb-0">
                  Our quick tutorial video walks you through the process of adding an email account to your LeaduxAI account.
                </p>
              </VCardText>
            </VCard>
          </VCol>

             
          <VCol
            cols="12"
             md="4"
            sm="6"
          >
            <VCard
              flat
              border
            >
              <div class="px-2 pt-2">
                <a href="https://www.leaduxai.io/resources/email-account-smtp-setup-guide" target="_blank">
                  <VImg
                     width="400px" height="200px"
                    :src="EmailImg"
                    class="tut-img"
                  />
                </a>
              </div>
              <VCardText>
                <h5 class="text-h5 mb-2">
                  <a href="https://www.leaduxai.io/resources/email-account-smtp-setup-guide" target="_blank">
                    Add an Email Account via SMTP
                    </a>
                </h5>
                <p class="text-body-1 mb-0">
                  Our comprehensive blog post guides you through every step of adding an email account via SMTP.
                </p>
              </VCardText>
            </VCard>
          </VCol>
          
        </VRow>
      </VCardText>
    </VCard>

  </div>
</template>

<style>
.v-data-table-header__content {
  text-transform: capitalize !important;
}
</style>



<style scoped>
.onboarding-video{
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

.tut-img{
  cursor: pointer;
}
.v-img__img{
    left: 15px !important;
    object-fit: cover !important;
}
</style>
