<script setup>
import { ref, onMounted, computed } from "vue";
import { useApiService } from "@/common/apiService";
import { useLoadingOverlay } from "@/composables/useLoadingOverlay";
import { useSnackbar } from "@/composables/useSnackbar";
import paperPlane from "@images/front-pages/icons/paper-airplane.png";
import plane from "@images/front-pages/icons/plane.png";
import pricingPlanArrow from "@images/front-pages/icons/pricing-plans-arrow.png";
import shuttleRocket from "@images/front-pages/icons/shuttle-rocket.png";
import bicycle from "@images/front-pages/icons/bicycle.png";
import { StripeCheckout } from "@vue-stripe/vue-stripe";


definePage({
  meta: {
    requiresAuth: true,
    title: "Pricing",
  },
});

const api = useApiService();
const loading = useLoadingOverlay();
const { showSnackbar } = useSnackbar();
const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
const readyToPay = ref(false);
const annualMonthlyPlanPriceToggler = ref(false);
const pricingPlans = ref([]);
const sessionId = ref(null);
const checkoutRef = ref(null);
const showConfirmDialog = ref(false);
const selectedPlan = ref(null);

const fetchPricingPlans = async () => {
  try {
    loading.start("Fetching plans...");
    const response = await api.get("/subscriptions/all-plan-details/");
    pricingPlans.value = response.data;
  } catch (error) {
    console.error("Error fetching pricing plans:", error);
    showSnackbar({
      message: "Failed to load pricing plans. Please try again.",
      color: "error",
    });
  } finally {
    loading.stop();
  }
};

const getPrice = (plan) => {
  if (annualMonthlyPlanPriceToggler.value) {
    return plan.yearly_price ? (plan.yearly_price / 12).toFixed(2) : plan.price;
  }
  return plan.price;
};

const getYearlyDiscount = (plan) => {
  if (plan.yearly_price) {
    const monthlyTotal = parseFloat(plan.price) * 12;
    const yearlyPrice = parseFloat(plan.yearly_price);
    const discount = ((monthlyTotal - yearlyPrice) / monthlyTotal) * 100;
    return discount.toFixed(0);
  }
  return 0;
};

const getImage = (plan) => {
  switch (plan.name) {
    case "Startup":
      return plane;
    case "Free":
      return bicycle;
    case "Pro":
      return paperPlane;
    case "Enterprise":
      return shuttleRocket;
    default:
      return "";
  }
};

const currentPlanIndex = computed(() => {
  return pricingPlans.value.findIndex((plan) => plan.current);
});

const getButtonText = (plan, index) => {
  if (plan.current) return "Current Plan";
  if (currentPlanIndex.value === -1) return "Get Started";
  if (index < currentPlanIndex.value) return "Downgrade";
  return "Upgrade";
};

const getButtonColor = (plan, index) => {
  if (plan.current) return "success";
  if (currentPlanIndex.value === -1) return "";
  return index < currentPlanIndex.value ? "default" : "primary";
};

const changePlan = async (plan) => {
  console.log("Changing plan to:", plan);
  if (plan.current) return;

  if (plan.current !== "FREE") {
    openConfirmDialog(plan.name);
    return;
  }

  // the user is on the free plan. Let's initiate the initial checkout
  initiateCheckout(plan.name);
};

const upgradeDowngradePlan = async (title) => {
  console.log("Upgrading/Downgrading plan to:", title);
  try {
    loading.start("Changing plan...");
    const response = await api.post(
      "/subscriptions/upgrade-downgrade/",
      {
        plan: title,
        is_yearly: annualMonthlyPlanPriceToggler.value,
      }
    );

    console.log("Plan changed successfully:", response.data);
    showSnackbar({
      message: "Plan changed successfully.",
      color: "success",
    });
    await fetchPricingPlans();
  } catch (error) {
    console.error("Error initiating checkout:", error);
    // removed snackbar for good user experience
    // showSnackbar({
    //   message: `Failed to initiate checkout. ${error.response?.data?.detail || 'Please try again.'}`,
    //   color: "error",
    //   timeout: 2000
    // });

    console.log("Since unable to upgradeDowngradePlan, proceed to Initiating checkout for plan:", title);
    // We should intiate the checkout if the plan change fails
    initiateCheckout(title);
  } finally {
    loading.stop();
  }
};

const initiateCheckout = async (title) => {
  console.log("Initiating checkout for plan:", title);
  try {
    loading.start("Changing plan...");
    readyToPay.value = true;
    await nextTick(); // wait for the next tick to ensure the stripe checkout is mounted

    const response = await api.get(
      `/subscriptions/create-checkout-session/?plan=${title}&is_yearly=${annualMonthlyPlanPriceToggler.value}`
    );
    console.log("sessionId:", response.data.sessionId);
    sessionId.value = response.data.sessionId;

    // You will be redirected to Stripe's secure checkout page

    if (checkoutRef.value) {
      checkoutRef.value.redirectToCheckout();
    } else {
      console.error("checkoutRef is not available");
    }
  } catch (error) {
    console.error("Error initiateCheckout:", error);
    showSnackbar({
      message: `Failed to change plan. ${error.response.data.detail}`,
      color: "error",
    });
  } finally {
    loading.stop();
  }
};

const openConfirmDialog = (plan) => {
  selectedPlan.value = plan;
  showConfirmDialog.value = true;
};

const confirmPlanChange = async () => {
  showConfirmDialog.value = false;
  await upgradeDowngradePlan(selectedPlan.value);
};

onMounted(fetchPricingPlans);
</script>

<template>
  <div id="pricing-plan">
    <VContainer>
      <div class="pricing-plans">
        <div class="text-center mb-10">
          <h2 class="text-h3 font-weight-bold mb-4">Pricing Plans</h2>
          <p class="text-body-1">
            Select from our range of plans designed to meet your needs and
            budget
          </p>
        </div>

        <!-- 👉 Annual and monthly price toggler -->

        <div
          class="d-flex font-weight-medium text-body-1 align-center justify-center mx-auto mt-12 mb-6"
        >
          <VLabel for="pricing-plan-toggle" class="me-3"> Monthly </VLabel>

          <div class="position-relative">
            <VSwitch
              id="pricing-plan-toggle"
              v-model="annualMonthlyPlanPriceToggler"
            >
              <template #label>
                <div class="text-body-1 font-weight-medium">Annually</div>
              </template>
            </VSwitch>

            <div
              class="save-upto-chip position-absolute align-center d-none d-md-flex gap-1"
            >
              <VIcon
                icon="tabler-corner-left-down"
                size="24"
                class="flip-in-rtl mt-2 text-disabled"
              />
              <VChip label color="primary" size="small"> Save up to 20% </VChip>
            </div>
          </div>
        </div>

        <VRow>
          <VCol
            v-for="(plan, index) in pricingPlans"
            :key="plan.uuid"
            cols="12"
            sm="6"
            md="3"
          >
            <VCard
              flat
              border
              :elevation="plan.name === 'Startup' || plan.current ? 8 : 2"
              :class="{
                'primary-border border-opacity-100': plan.current,
              }"
            >
              <VCardText style="block-size: 3.75rem" class="text-end">
                <!-- 👉 Popular -->
                <VChip
                  v-show="plan.name === 'Startup'"
                  label
                  color="primary"
                  size="small"
                >
                  Popular
                </VChip>
              </VCardText>
              <VCardItem>
                <VCardTitle class="text-h5 font-weight-bold">{{
                  plan.name
                }}</VCardTitle>
                <VCardSubtitle v-if="plan.has_free_trial" class="mt-2">
                  {{ plan.free_trial_duration }} days free trial
                </VCardSubtitle>
              </VCardItem>

              <VCardText>
                <VImg
                  :src="getImage(plan)"
                  width="88"
                  height="88"
                  class="mx-auto mb-8"
                />
                <div class="text-h4 font-weight-bold mb-2">
                  ${{ getPrice(plan) }}
                  <span class="text-body-2 text-disabled font-weight-regular"
                    >/month</span
                  >
                </div>
                <div
                  v-if="annualMonthlyPlanPriceToggler && plan.yearly_price"
                  class="text-caption mb-4"
                >
                  Billed annually at ${{ plan.yearly_price }}/year
                  <VChip x-small color="success" class="ml-0"
                    >Save {{ getYearlyDiscount(plan) }}%</VChip
                  >
                </div>
                <p class="text-body-2">{{ plan.description }}</p>

                <VDivider class="my-4" />

                <VList class="card-list">
                  <VListItem
                    v-for="feature in plan.features"
                    :key="feature"
                    class="px-0"
                  >
                    <template v-slot:prepend>
                      <VAvatar
                        size="16"
                        :variant="!plan.current ? 'tonal' : 'elevated'"
                        color="primary"
                        class="me-3"
                      >
                        <VIcon
                          icon="tabler-check"
                          size="12"
                          :color="!plan.current ? 'primary' : 'white'"
                        />
                      </VAvatar>
                    </template>

                    <h6 class="text-h6">
                      {{ feature }}
                    </h6>
                  </VListItem>

                   <!-- todo add , to numbers 50,000 instead of 5000 -->
                </VList>

                <VDivider class="my-4" />

                <div class="text-caption mb-2">
                  <strong>Support:</strong> {{ plan.support_type }} ({{
                    plan.support_medium
                  }})
                </div>
                <div class="text-caption">
                  <strong>Response Time:</strong> {{ plan.respond_time }}
                </div>
              </VCardText>

              <VCardActions>
                <VBtn
                  block
                  :color="getButtonColor(plan, index)"
                  variant="flat"
                  :active="false"
                  :disabled="plan.current || plan.name === 'Free'"
                  @click="changePlan(plan)"
                >
                  {{ getButtonText(plan, index) }}
                </VBtn>
              </VCardActions>
            </VCard>
          </VCol>
        </VRow>
      </div>
    </VContainer>

    <StripeCheckout
    v-if="readyToPay"
      ref="checkoutRef"
      :pk="STRIPE_PUBLISHABLE_KEY"
      :session-id="sessionId"
    />

    <v-dialog v-model="showConfirmDialog" max-width="400px">
      <v-card>
        <v-card-title>Confirm Plan Change</v-card-title>
        <v-card-text>
          Are you sure you want to change your plan to
          <strong>{{ selectedPlan }}</strong
          >?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showConfirmDialog = false"
            >Cancel</v-btn
          >
          <VBtn color="primary" @click="confirmPlanChange"> Confirm </VBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.primary-border {
  border: 2px solid rgb(var(--v-theme-primary));
}
</style>

<style lang="scss" scoped>
.card-list {
  --v-card-list-gap: 12px;
}

#pricing-plan {
  border-radius: 3.75rem;
  background-color: rgb(var(--v-theme-background));
}

.pricing-title {
  font-size: 38px;
  font-weight: 800;
  line-height: 52px;
}

.pricing-plans {
  margin-block: 5.25rem;
}

@media (max-width: 600px) {
  .pricing-plans {
    margin-block: 4rem;
  }
}

.save-upto-chip {
  inset-block-start: -1.5rem;
  inset-inline-end: -7rem;
}

.pricing-plan-arrow {
  inset-block-start: -0.5rem;
  inset-inline-end: -8rem;
}

.section-title {
  font-size: 24px;
  font-weight: 800;
  line-height: 36px;
}

.section-title::after {
  position: absolute;
  background: url("../../../assets/images/front-pages/icons/section-title-icon.png")
    no-repeat left bottom;
  background-size: contain;
  block-size: 100%;
  content: "";
  font-weight: 700;
  inline-size: 120%;
  inset-block-end: 0;
  inset-inline-start: -12%;
}

.annual-price-text {
  inset-block-end: -40%;
}
</style>
