import { ref, provide, inject } from "vue";

const loadingKey = Symbol("loading-overlay");

export function provideLoadingOverlay() {
  const isVisible = ref(false);
  const message = ref("Loading...");

  const start = (customMessage) => {
    message.value = customMessage || "Loading...";
    isVisible.value = true;
  };

  const stop = () => {
    isVisible.value = false;
  };

  provide(loadingKey, {
    isVisible,
    message,
    start,
    stop,
  });
}

export function useLoadingOverlay() {
  const loading = inject(loadingKey);
  if (!loading) {
    throw new Error(
      "Loading overlay is not provided. Did you forget to call provideLoadingOverlay?"
    );
  }
  return loading;
}
