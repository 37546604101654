<script setup>
import { useLoadingOverlay } from '@/composables/useLoadingOverlay'

const { isVisible, message } = useLoadingOverlay()
</script>

<template>
  <Teleport to="body">
    <div v-if="isVisible" class="loading-overlay">
      <div class="loading-content">
        <VProgressCircular
          indeterminate
          size="64"
          color="primary"
        ></VProgressCircular>
        <div class="loading-message">{{ message }}</div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: wait;
}

.loading-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.loading-message {
  margin-top: 16px;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}
</style>
