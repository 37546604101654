<!-- src/components/GlobalSnackbar.vue -->
<script setup>
import { useSnackbar } from '@/composables/useSnackbar'

const { isVisible, message, color, timeout, hideSnackbar } = useSnackbar()
</script>

<template>
  <VSnackbar
    v-model="isVisible"
    :color="color"
    :timeout="timeout"
    @update:modelValue="hideSnackbar"
  >
    {{ message }}
    
    <template v-slot:actions>
      <VBtn
        color="white"
        text
        @click="hideSnackbar"
      >
        Close
      </VBtn>
    </template>
  </VSnackbar>
</template>
