<script setup>
import { useGenerateImageVariant } from "@core/composable/useGenerateImageVariant";
import authV2LoginIllustrationBorderedDark from "@images/pages/auth-v2-login-illustration-bordered-dark.png";
import authV2LoginIllustrationBorderedLight from "@images/pages/auth-v2-login-illustration-bordered-light.png";
import authV2LoginIllustrationDark from "@images/pages/brain.png";
import authV2LoginIllustrationLight from "@images/pages/brain.png";
import authV2MaskDark from "@images/pages/misc-mask-dark.png";
import authV2MaskLight from "@images/pages/misc-mask-light.png";
import { VNodeRenderer } from "@layouts/components/VNodeRenderer";
import { themeConfig } from "@themeConfig";
import { useApiService } from "@/common/apiService";
import { useAuthStore } from "@/store/auth";
import { useRouter } from "vue-router";
import GoogleSignIn from '@/components/auth/GoogleSignIn.vue'

import { useLoadingOverlay } from '@/composables/useLoadingOverlay'

const loading = useLoadingOverlay()

definePage({
  meta: {
    requiresAuth: false,
    title: "Login",
    layout: "blank",
  },
});
const form = ref({
  email: "",
  password: "",
  remember: false,
});

const isPasswordVisible = ref(false);
const authThemeImg = useGenerateImageVariant(
  authV2LoginIllustrationLight,
  authV2LoginIllustrationDark,
  authV2LoginIllustrationBorderedLight,
  authV2LoginIllustrationBorderedDark,
  true
);
const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark);

const apiService = useApiService();
const authStore = useAuthStore();
const router = useRouter();

const errorMessage = ref("");
const isLoading = ref(false);

const handleLogin = async () => {
  errorMessage.value = ''
  isLoading.value = true
  loading.start('Logging in...')

  try {
    const response = await apiService.post('/users/login/', {
      email: form.value.email,
      password: form.value.password,
    })

    console.log(response)
    // Check if the response has the expected structure
    if (response.data && response.data.access && response.data.refresh) {
      console.log('Login successful')

      // Update the auth store with the new data structure
      authStore.setAuthData(response.data)


      // Set timezone after successful login
      await setTimeZone();


      // Redirect to dashboard or home page
      router.push({ name: 'root' })

    } else {
      errorMessage.value = 'Invalid response from server. Please try again.'
    }
  } catch (error) {
    if (error.response) {
      errorMessage.value = error.response.data.detail || 'An error occurred during login.'
    } else {
      errorMessage.value = 'Network error. Please try again later.'
      console.error(error)
    }
  } finally {
    isLoading.value = false
    loading.stop()
  }
}


const setTimeZone = async () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  try {
    await apiService.post("/users/set-timezone/", { timezone });
    console.log("Timezone set successfully");
  } catch (error) {
    console.error("Error setting timezone:", error);
    // Optionally show a non-blocking error message to the user
  }
};
</script>

<template>
  <RouterLink to="/">
    <div class="auth-logo d-flex align-center gap-x-3">
      <VNodeRenderer :nodes="themeConfig.app.logo" />
      <h1 class="auth-title">
        {{ themeConfig.app.title }}
      </h1>
    </div>
  </RouterLink>

  <VRow no-gutters class="auth-wrapper bg-surface">
    <VCol md="8" class="d-none d-md-flex">

      <div class="position-relative bg-background w-100 me-0">
        <div
          class="d-flex align-center justify-center w-100 h-100"
          style="padding-inline: 6.25rem"
        >
          <VImg
            max-width="613"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <img
          class="auth-footer-mask"
          :src="authThemeMask"
          alt="auth-footer-mask"
          height="280"
          width="100"
        />
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard flat :max-width="500" class="mt-12 mt-sm-0 pa-4">
        <VCardText>
          <h4 class="text-h4 mb-1">
            Welcome Back, Sales Superstar! 💫
          </h4>
          <p class="mb-0">
            Ready to conquer more leads and close more deals? Let's get you logged in!
          </p>
        </VCardText>

        <VCardText>
          <VForm @submit.prevent="handleLogin">
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.email"
                  autofocus
                  label="Email"
                  type="email"
                  placeholder="johndoe@email.com"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.password"
                  label="Password"
                  placeholder="············"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="
                    isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />

                <div
                  class="d-flex align-center flex-wrap justify-space-between mt-2 mb-4"
                >
                  <VCheckbox v-model="form.remember" label="Remember me" />
                  <a class="text-primary ms-2 mb-1" href="/forgot-password">
                    Forgot Password?
                  </a>
                </div>

                <VBtn
                  block
                  type="submit"
                  :loading="isLoading"
                  :disabled="isLoading"
                >
                  Login
                </VBtn>

                <p v-if="errorMessage" class="text-error mt-2">
                  {{ errorMessage }}
                </p>
              </VCol>

              <!-- create account -->
              <VCol cols="12" class="text-center">
                <span>New on our platform?</span>

                <a class="text-primary ms-2" href="/register"> Create an account </a>
              </VCol>
              <VCol cols="12" class="d-flex align-center">
                <VDivider />
                <span class="mx-4">or</span>
                <VDivider />
              </VCol>

              <!-- auth providers -->
              <VCol cols="12" class="text-center">
                <GoogleSignIn/>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>

<style>
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.auth-illustration {
  animation: floatAnimation 3s ease-in-out infinite;
}
</style>
