<script setup>
import sad from "@images/pages/sad.png";
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useApiService } from "@/common/apiService";
import { useLoadingOverlay } from "@/composables/useLoadingOverlay";
import { useSnackbar } from "@/composables/useSnackbar";

const loading = useLoadingOverlay();
const api = useApiService();
const { showSnackbar } = useSnackbar();

// Page meta
definePage({
  meta: {
    layout: "blank",
    public: false,
    requiresAuth: true,
  },
});

const route = useRoute();
const router = useRouter();

onMounted(() => {
  // Extract token from URL
  const token = route.params.token;
  console.log("Payment success token:", token);

  if (!token) {
    console.error("Payment token not found");
    showSnackbar({
      message: "Payment token not found",
      color: "error",
    });
    router.push("/");
    return;
  }
});
</script>

<template>
  <div class="misc-wrapper">
    <div class="text-center mb-15">
      <!-- 👉 Title and subtitle -->
      <h4 class="text-h4 font-weight-medium mb-2">Payment Failed 🥺</h4>
      <p class="text-body-1 mb-6">
        We are sorry, but your payment has failed or was cancelled. Please
        try again.
      </p>

      <VBtn to="/"> Go to dashboard </VBtn>
    </div>

    <!-- 👉 Image -->
    <div class="misc-avatar w-100 text-center">
      <VImg
        :src="sad"
        alt="Failed payment"
        :max-width="200"
        :min-height="250"
        class="mx-auto"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@core/scss/template/pages/misc.scss";
</style>
