<script setup>
import googleImage from "@images/illustrations/google.png";

definePage({
  meta: {
    requiresAuth: true,
    title: "Email Accounts - Google",
  },
});
</script>

<template>
  <div>
    <VRow justify="center" style="margin-block: 10rem 0" v-if="false">
      <VCol cols="12">
        <VCard class="mb-6">
          <VCardText class="py-12 position-relative">
            <div
              class="d-flex flex-column gap-y-4 mx-auto"
              :class="
                $vuetify.display.mdAndUp
                  ? 'w-50'
                  : $vuetify.display.xs
                  ? 'w-100'
                  : 'w-75'
              "
            >
              <div class="align-self-center">
                <div class="align-self-center">
                  <img :src="googleImage" height="90" class="flip-in-rtl" />
                </div>
              </div>

              <h4
                class="text-h4 text-center text-wrap mx-auto"
                :class="$vuetify.display.mdAndUp ? 'w-75' : 'w-100'"
              >
                Connect your Google Workspace Account
              </h4>
              <p class="text-center text-wrap text-body-1 mx-auto mb-0">
                Follow the steps below to connect your Google account.
              </p>

              <div
                class="d-flex flex-wrap justify-space-between flex-column rounded bg-var-theme-background flex-sm-row gap-6 pa-6 mb-6"
              >
                <div>
                  <p class="text-high-emphasis mb-2">
                    1. Please go to your
                    <a
                      href="https://admin.google.com/u/1/ac/owl/list?tab=configuredApps"
                      target="_blank"
                      >Google Cloud Console</a
                    >
                  </p>
                  <p class="text-high-emphasis mb-2">
                    2. Click on <b>"Add app"</b>, and then choose
                    <b>OAuth App Name or Client ID</b>
                  </p>
                  <p class="text-high-emphasis mb-2">
                    3. Search for the LeaduxAI app using the following client
                    code:
                  </p>

                  <VCard
                    flat
                    class="pa-4"
                    color="rgba(var(--v-theme-on-surface),var(--v-hover-opacity))"
                  >
                    <div class="d-flex flex-column gap-y-2">
                      <div
                        class="d-flex align-center text-base font-weight-medium"
                      >
                        <h6 class="text-h6 text-medium-emphasis me-3">
                          23eaf7f0-f4f7-495e-8b86-fad3261282ac
                        </h6>
                        <div class="cursor-pointer">
                          <VIcon icon="tabler-copy" size="20" />
                        </div>
                      </div>
                      <div class="text-disabled">Google Client ID</div>
                    </div>
                  </VCard>

                  <p class="text-high-emphasis mb-2 mt-2">
                    4. Select <b>LeaduxAI</b> and mark the app as Trusted, and
                    click the submit button.
                  </p>

                  <p class="text-high-emphasis mb-2">
                    5. Connect your Google account to LeaduxAI by clicking the
                    <b>Connect</b> button below. You will be redirected to
                    Google to authorize LeaduxAI to access your account.
                  </p>
                </div>
              </div>

              <div class="d-flex flex-wrap gap-4 justify-center">
                <VBtn color="secondary" variant="tonal" :to="'/email-accounts'"
                  >Cancel</VBtn
                >

                <VBtn variant="outlined">
                  Connect
                  <VIcon end icon="tabler-brand-google-filled" />
                </VBtn>
              </div>
            </div>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </div>
</template>

<style scoped>
/* Add your custom styles here */
</style>
